<div [dir]="'dir' | translate">
    <mat-dialog-content #dialogContent>
        <h1 class="title" mat-dialog-title translate>
            <mat-icon>forward_to_inbox</mat-icon>&nbsp;
            <label translate>transaction_send_voucher_dialog.title</label>
        </h1>
        <div>
            <mat-form-field appearance="fill">
                <mat-label translate>payment.trans_id</mat-label>
                <input matInput type="text" #transId="ngModel" [(ngModel)]="data.transId" readonly min="1" required>
                <mat-error *ngIf="transId.hasError('required')" translate>
                    errors.required_field
                </mat-error>
                <mat-error *ngIf="transId.hasError('min')" translate>
                    payment.invalid_transaction_id
                </mat-error>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field appearance="fill" class="email-container">
                <mat-label translate>email</mat-label>
                <input matInput type="email" #email="ngModel" [(ngModel)]="data.email" required email
                    name="mp_voucher_email">
                <mat-error *ngIf="email.hasError('required')" translate>
                    errors.required_field
                </mat-error>
                <mat-error *ngIf="email.hasError('email')" translate>
                    errors.invalid_email_value
                </mat-error>
            </mat-form-field>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-stroked-button color="primary" type="button" (click)="onCancel()">
            <label translate>dialog.cancel</label>
        </button>
        <button mat-flat-button color="primary" (click)="onSubmit()" cdkFocusInitial>
            <label translate>dialog.submit</label>
        </button>
    </mat-dialog-actions>
</div>