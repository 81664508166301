<div class="login-container">

    <div class="wrapper">
        <div class="version-container" translate [translateParams]="{version:websiteVersion}">version</div>
        <div #loginDiv class="login">
            <div class="header-container">
                <app-select-website-language></app-select-website-language>
                <img *ngIf="company===companyTypeValues.VERIFONE" src="{{verifoneLogoUrl}}">
                <img *ngIf="company===companyTypeValues.ISRACARD" src="{{isracardLogoUrl}}">
            </div>
            <form (ngSubmit)="onSubmit($event)" [formGroup]="loginFormGroup">
                <p class="title" translate>login.title</p>
                <mat-form-field appearance="fill">
                    <mat-label translate>auth.username</mat-label>
                    <input matInput type="text" formControlName="username" name="tgw_mp_username">
                    <mat-error *ngIf="loginFormGroup.controls['username'].hasError('required')" translate>
                        errors.required_field
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label translate>auth.password</mat-label>
                    <input matInput [type]="passwordHide ? 'password' : 'text'" formControlName="password"
                        name="tgw_mp_password">
                    <button type="button" mat-icon-button matSuffix (click)="passwordHide = !passwordHide"
                        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="passwordHide">
                        <mat-icon>{{passwordHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    <mat-error *ngIf="loginFormGroup.controls['password'].hasError('required')" translate>
                        errors.required_field
                    </mat-error>
                </mat-form-field>

                <div *ngIf="authError" class="error-msg">{{authError}}</div>

                <a class="forgot-password" (click)="forgotPassword()" translate>login.forgot_your_password</a>
                <button class="submit-btn">
                    <i class="spinner"></i>
                    <span #buttonState class="state" translate>login.submit_text</span>
                </button>
            </form>
        </div>
        <footer>
            <a target="blank" href="https://verifone.co.il/" translate>verifone_israel</a>
        </footer>
    </div>
</div>