import { Constants } from './../infrastructure/constants';
import { EventEmitter, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TranslateConfigService {
  private static dir = 'ltr';
  private static readonly defaultLangKey = "he";
  public readonly onLangChange = new EventEmitter<void>();

  constructor(private translateService: TranslateService) {
    this.translateService.setDefaultLang(TranslateConfigService.defaultLangKey);
    this.translateService.onLangChange.subscribe(() => {
      this.onLangChange.emit();
      TranslateConfigService.dir = this.getValue('dir');
    });
    const lang = localStorage.getItem(Constants.LanguageKey) ?? TranslateConfigService.defaultLangKey;
    this.translateService.use(lang);
  }

  static getDir() {
    return TranslateConfigService.dir;
  }

  changeLanguage(type: string) {
    this.translateService.use(type);
    localStorage.setItem(Constants.LanguageKey, type);
  }

  getCurrentLang(): string {
    return this.translateService.currentLang;
  }

  getTranslateService() {
    return this.translateService;
  }

  async getValueAsync(key: string, params: Object = null): Promise<string> {
    let result = '';
    try {
      const res = await this.translateService.get(key, params).toPromise();
      result = res as string;
    }
    catch (err) { }
    return result ?? key;
  }

  getValue(key: string, params: Object = null): string {
    let result = '';
    try {
      const res = this.translateService.instant(key, params);
      result = res as string;
    }
    catch (err) { }
    return result ?? key;
  }
}
