import { Injectable } from '@angular/core';
import { Constants } from '../infrastructure/constants';

@Injectable({
  providedIn: 'root'
})
export class CookieService {
  private readonly SESSION_ID_KEY = "sessionId";
  readonly sessionId: string = '';

  private makeId(length: number) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  constructor() {
    this.sessionId = sessionStorage.getItem(this.SESSION_ID_KEY);
    if (!this.sessionId) {
      this.sessionId = this.makeId(36);
      sessionStorage.setItem(this.SESSION_ID_KEY, this.sessionId);
    }
  }

  getCookie(name: string): string {
    let ca: Array<string> = document.cookie.split(';');
    let caLen: number = ca.length;
    let cookieName = `${name}_${this.sessionId}=`;
    let c: string;
    for (let i: number = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '').trim();
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }

  deleteCookie(name: string) {
    let d: Date = new Date();
    d.setTime(d.getTime() - (60 * 1000));
    this.setCookie(name, '', d);
  }

  setCookie(name: string, value: string, expireTime: Date, path: string = '') {
    let expires: string = `expires=${expireTime.toUTCString()}`;
    let cpath: string = path ? `; path=${path}` : '';
    document.cookie = `${name}_${this.sessionId}=${value}; ${expires}${cpath}`;
  }

  deleteAllCookies() {
    this.deleteCookie(Constants.currentUserKey);
    this.deleteCookie(Constants.currentUserExpKey);
  }
}
