import { TransactionTypeValues } from "../dtos/tgw/transaction-type-values";
import { ILanguageItem } from "./interfaces/language-item";
import { CompanyType } from "./types/company.type";

export class Constants {
    //regex
    public static readonly merchantPasswordRule = new RegExp(/((?=.*\d)(?=.*[a-z A-Z]).{7})/);

    //header keys
    public static readonly notifyErrorOffKey = "notify-error-off";
    public static readonly loadingOffKey = "loading-off";

    //dialog objects
    public static readonly getNotifyErrorOffKeyObj = () => {
        const res: any = {};
        res[Constants.notifyErrorOffKey] = "off";
        return res;
    };
    public static readonly getLoadingOffKeyObj = () => {
        const res: any = {};
        res[Constants.loadingOffKey] = "off";
        return res;
    };


    //global 
    public static readonly secondsBeforeLeaveTheSystem: number = 30;
    public static readonly defaultPage = '/home';
    public static readonly languagesValues = {
        Hebrew: 'he',
        English: 'en'
    };
    public static readonly languages: ILanguageItem[] = [
        {
            name: 'עברית',
            iconClasses: 'flag-icon flag-icon-il flag-icon-squared',
            value: Constants.languagesValues.Hebrew
        },
        {
            name: 'English',
            iconClasses: 'flag-icon flag-icon-us flag-icon-squared',
            value: Constants.languagesValues.English
        }
    ];

    //storage keys
    public static readonly currentUserKey: string = "current_user";
    public static readonly currentUserExpKey: string = "current_user_exp";
    public static readonly tablePageSizeKey: string = "table_page_size";
    public static readonly LanguageKey = "website_language";


    //Values
    public static readonly TransactionTypeValues = {
        credit: { value: TransactionTypeValues.CREDIT, text: 'payment.transaction_type_values.credit' },
        cash: { value: TransactionTypeValues.CASH, text: 'payment.transaction_type_values.cash' },
        check: { value: TransactionTypeValues.CHECK, text: 'payment.transaction_type_values.check' }
    }

    public static readonly VerifoneLogoUrlBlack = 'https://vf-public-files.s3.eu-west-1.amazonaws.com/images/Verifone/verifone_logotype_black.png';
    public static readonly IsracardLogoUrl = 'https://vf-public-files.s3.eu-west-1.amazonaws.com/images/Isracard/logo-03.png';
    public static readonly IsracardLogoUrlWhite = 'https://vf-public-files.s3.eu-west-1.amazonaws.com/images/Isracard/logo-03_W.png'
    public static readonly VerifoneLogoUrlWhite = 'https://vf-public-files.s3.eu-west-1.amazonaws.com/images/Verifone/verifone_logotype_white.png';
}   