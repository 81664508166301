import { Constants } from './../../../infrastructure/constants';
import { CompanyType } from './../../../infrastructure/types/company.type';
import { AuthService } from './../../../services/auth.service';
import { Component } from '@angular/core';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  companyTypeValues = CompanyType;
  company: CompanyType = this.authService.getUserCompany();
  verifoneLogoUrl = Constants.VerifoneLogoUrlBlack;
  isracardLogoUrl = Constants.IsracardLogoUrl;

  constructor(
    private authService: AuthService
  ) { }
}
