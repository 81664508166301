import { TransactionNotesDialogData } from './../../../dtos/dialogs/transaction-notes-dialog-data';
import { TranslateConfigService } from './../../../services/translate-config.service';
import { TgwTransactionService } from '../../../services/tgw/tgw-transaction.service';
import { NotifyService } from './../../../services/notify.service';
import { Component, Inject, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TgwApiUtils } from './../../../utils/tgw-api-utils';

@Component({
  selector: 'app-transaction-notes-dialog',
  templateUrl: './transaction-notes-dialog.component.html',
  styleUrls: ['./transaction-notes-dialog.component.scss']
})
export class TransactionNotesDialogComponent {
  @ViewChild('transId') transId: NgModel;
  @ViewChild('notes') notes: NgModel;

  loading: boolean = false;
  data = {
    transId: this.requestData?.transaction?.transId ?? 0,
    notes: this.requestData?.transaction?.notes ?? ''
  };
  constructor(
    private dialogRef: MatDialogRef<TransactionNotesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public requestData: TransactionNotesDialogData,
    private translateConfigService: TranslateConfigService,
    private notify: NotifyService,
    private tgwTransactionService: TgwTransactionService
  ) { }

  async onSubmit() {
    if (this.transId.invalid || this.notes.invalid) {
      return;
    }

    try {
      const response = await this.tgwTransactionService.updateNotes(this.data.transId, this.data.notes).toPromise();
      if (TgwApiUtils.TgwResponseIsSuccess(response)) {
        if (this.requestData.updateSuccessCallBack)
          this.requestData.updateSuccessCallBack(this.data.transId, this.data.notes);
        this.dialogRef.close();
        this.notify.success(await this.translateConfigService.getValueAsync('dialog.successfully_updated'));
      }
    } catch { }
    finally {
      this.loading = false;
    }
  }
  onCancel() {
    this.dialogRef.close();
  }
}
