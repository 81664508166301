<div class="footer-container">
    <div class="copyright-container">
        <label>
            © {{yearOfDevelopment}}<ng-container *ngIf="currentYear>yearOfDevelopment"> - {{currentYear}}</ng-container>
            Verifone.com, Inc
        </label>
    </div>
    <div class="version-container">
        <label [dir]="'dir' | translate" translate [translateParams]="{version:websiteVersion}">version</label>
    </div>
</div>