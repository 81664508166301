import { TransactionErrorHistoryFilterDialogData } from './../../../dtos/dialogs/transaction-error-history-filter-dialog-data';
import { TransactionErrorHistoryRequest } from './../../../dtos/tgw/transaction-error-history-request';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-transaction-error-history-filter-dialog',
  templateUrl: './transaction-error-history-filter-dialog.component.html',
  styleUrls: ['./transaction-error-history-filter-dialog.component.scss']
})
export class TransactionErrorHistoryFilterDialogComponent {
  data: TransactionErrorHistoryRequest;

  constructor(
    private dialogRef: MatDialogRef<TransactionErrorHistoryFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public requestData: TransactionErrorHistoryFilterDialogData
  ) {
    this.data = requestData.request;
  }

  async onFilter() {
    if (this.data?.dateStart)
      this.data.dateStart += " 00:00:00";

    if (this.data?.dateEnd)
      this.data.dateEnd += " 23:59:59";

    if (this.requestData.callBack)
      this.requestData.callBack(this.data)
    this.dialogRef.close();
  }

  onReset() {
    this.data = {};
  }

  onCancel() {
    this.dialogRef.close();
  }
}
