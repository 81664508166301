import { MerchantTypeValues } from './../dtos/tgw/merchant-type-values';
import { CompanyType } from './../infrastructure/types/company.type';
import { Constants } from './../infrastructure/constants';
import { DialogService } from './dialog.service';
import { CurrentUserDTO } from './../dtos/current-user.dto';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from './cookie.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private static currentUser: CurrentUserDTO = null;
  private static accessTokenExpTimeout: any = null;

  constructor(
    private router: Router,
    private cookieService: CookieService,
    private dialogService: DialogService
  ) { }

  private restoreCurrentUser(): CurrentUserDTO {
    try {
      const currentUserJson = this.cookieService.getCookie(Constants.currentUserKey);
      if (currentUserJson) {
        const currentUser = JSON.parse(currentUserJson);
        const currentUserExpJson = this.cookieService.getCookie(Constants.currentUserExpKey);
        if (currentUserExpJson) {
          const currentUserExp = JSON.parse(currentUserExpJson);
          const now: Date = new Date();
          this.startAccessTokenExpTimeout((currentUserExp - now.getTime()) / 1000);
          return currentUser;
        }
      }
    }
    catch (err) {
      this.cookieService.deleteCookie(Constants.currentUserKey);
    }
    return null;
  }

  resetCookieExpirationTime() {
    this.setCurrentUser(this.getCurrentUser());
  }

  setCurrentUser(data: CurrentUserDTO) {
    AuthService.currentUser = data;
    if (data) {
      this.cookieService.getCookie
      let d: Date = new Date();
      const time = d.getTime() + data.ttl * 60 * 1000;
      d.setTime(time);
      this.cookieService.setCookie(Constants.currentUserKey, JSON.stringify(data), d);
      this.cookieService.setCookie(Constants.currentUserExpKey, JSON.stringify(time), d);
      this.startAccessTokenExpTimeout(data.ttl * 60);
    } else {
      this.cookieService.deleteCookie(Constants.currentUserKey);
      this.cookieService.deleteCookie(Constants.currentUserExpKey);
      this.stopAccessTokenExpTimeout();
    }
  }

  getCurrentUser(): CurrentUserDTO {
    if (!AuthService.currentUser)
      AuthService.currentUser = this.restoreCurrentUser();
    return AuthService.currentUser;
  }

  getUserCompany(): CompanyType {
    const user = this.getCurrentUser();
    switch (user?.merchantType ?? MerchantTypeValues.VERIFONE) {
      default:
      case MerchantTypeValues.VERIFONE:
        return CompanyType.VERIFONE;
      case MerchantTypeValues.ISRACARD:
        return CompanyType.ISRACARD;
    }
  }

  logout(returnUrl: string = null) {
    let queryParams: any = { company: this.getUserCompany() };
    this.setCurrentUser(null);

    if (returnUrl)
      queryParams.returnUrl = returnUrl;

    if (!returnUrl?.startsWith("login") && !returnUrl?.startsWith("/login"))
      this.router.navigate(['/login'], { queryParams: queryParams });
  }

  private startAccessTokenExpTimeout(secondsToTokenExpiry: number) {
    this.stopAccessTokenExpTimeout();
    AuthService.accessTokenExpTimeout = setTimeout(() => {
      this.dialogService.openAlertPreLeaveDialog();
    }, (Math.floor(secondsToTokenExpiry) - Constants.secondsBeforeLeaveTheSystem) * 1000);
  }

  private stopAccessTokenExpTimeout() {
    if (AuthService.accessTokenExpTimeout) {
      clearTimeout(AuthService.accessTokenExpTimeout);
      AuthService.accessTokenExpTimeout = null;
    }
  }
}
