import { AuthService } from './../../../services/auth.service';
import { CompanyType } from './../../../infrastructure/types/company.type';
import { IMenuItem } from '../../../infrastructure/interfaces/menu-item';
import { Component, OnInit } from '@angular/core';
import { Constants } from './../../../infrastructure/constants';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {
  companyTypeValues = CompanyType;
  company: CompanyType = this.authService.getUserCompany();
  verifoneLogoUrlWhite = Constants.VerifoneLogoUrlWhite;
  isracardLogoUrlWhite = Constants.IsracardLogoUrlWhite;

  //https://fonts.google.com/icons?selected=Material+Icons
  menuItems: IMenuItem[] = [
    { text: 'menu.transactions', icon: 'shopping_cart', routerLink: '/transactions' },
    { text: 'menu.transactions_errors', icon: 'error_outline', routerLink: '/transactionsErrors' },
    // { text: 'menu.recurring_orders', icon: 'add_shopping_cart', routerLink: '/recurringOrders' },
    { text: 'menu.deposit_reports', icon: 'receipt_long', routerLink: '/depositReports' },
    { text: 'menu.transactions_deposit', icon: 'ios_share', routerLink: '/transactionsDeposit' }
  ];
  constructor(private authService: AuthService) { }
}
