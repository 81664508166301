<div class="wrapper">
    <form #changePasswordForm (ngSubmit)="onSubmit($event)" [formGroup]="changePassFormGroup">
        <mat-form-field appearance="fill">
            <mat-label translate>auth.username</mat-label>
            <input matInput type="text" formControlName="username" name="tgw_mp_username" readonly="true">
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label translate>auth.password</mat-label>
            <input matInput [type]="hide.password ? 'password' : 'text'" formControlName="password"
                name="tgw_mp_password">
            <button type="button" mat-icon-button matSuffix (click)="hide.password = !hide.password"
                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide.password">
                <mat-icon>{{hide.password ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-error *ngIf="changePassFormGroup.controls['password'].hasError('required')" translate>
                errors.required_field
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label translate>auth.new_password</mat-label>
            <input matInput [type]="hide.newPassword ? 'password' : 'text'" formControlName="newPassword"
                name="tgw_mp_new_password" #newPass>
            <button type="button" mat-icon-button matSuffix (click)="hide.newPassword = !hide.newPassword"
                [attr.aria-label]="'Hide new password'" [attr.aria-pressed]="hide.newPassword">
                <mat-icon>{{hide.newPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-error *ngIf="changePassFormGroup.controls['newPassword'].hasError('required')" translate>
                errors.required_field
            </mat-error>
            <mat-error *ngIf="changePassFormGroup.controls['newPassword'].hasError('pattern')" translate>
                auth.errors.new_password_policy_error
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label translate>auth.confirm_new_password</mat-label>
            <input matInput [type]="hide.confirmNewPassword ? 'password' : 'text'"
                formControlName="confirmNewPassword" name="tgw_mp_confirm_new_password" #confirmNewPass>
            <button type="button" mat-icon-button matSuffix (click)="hide.confirmNewPassword = !hide.confirmNewPassword"
                [attr.aria-label]="'Hide confirm new password'" [attr.aria-pressed]="hide.confirmNewPassword">
                <mat-icon>{{hide.confirmNewPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-error *ngIf="changePassFormGroup.controls['confirmNewPassword'].hasError('required')" translate>
                errors.required_field
            </mat-error>
            <mat-error *ngIf="changePassFormGroup.controls['confirmNewPassword'].hasError('passwordsMatch')" translate>
                auth.errors.passwords_does_not_match
            </mat-error>
        </mat-form-field>
        <div *ngIf="changePassError" class="error-msg">{{changePassError}}</div>
        <button class="submit-btn">
            <i class="spinner"></i>
            <span #buttonState class="state" translate>change_password.submit_text</span>
        </button>
    </form>
</div>