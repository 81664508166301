<div [dir]="'dir' | translate">
    <mat-dialog-content #dialogContent>
        <h1 class="title" mat-dialog-title translate>
            <mat-icon>warning</mat-icon>&nbsp;
            <label translate>alert_pre_leave.title</label>
        </h1>
        <h2 [translateParams]="{seconds: seconds}" translate>alert_pre_leave.msg_line1</h2>
        <h2 translate>alert_pre_leave.msg_line2</h2>
    </mat-dialog-content>
    <mat-dialog-actions align="end" *ngIf="!loading">
        <button mat-stroked-button color="primary" *ngIf="!loading" (click)="onCancel()"><label
                translate>dialog.cancel</label></button>
        <button mat-flat-button color="primary" *ngIf="!loading" (click)="onRenewActivity()" cdkFocusInitial>
            <label translate>alert_pre_leave.renew_activity</label></button>
        <label *ngIf="loading" translate>dialog.please_wait</label>
    </mat-dialog-actions>
</div>