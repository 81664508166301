import { TransactionErrorHistoryRequest } from '../../dtos/tgw/transaction-error-history-request';
import { TransactionErrorHistoryResponse } from '../../dtos/tgw/transaction-error-history-response';
import { SendTransactionVoucherRequest } from '../../dtos/tgw/send-transaction-voucher-request';
import { SendTransactionVoucherResponse } from '../../dtos/tgw/send-transaction-voucher-response';
import { UpdateTransactionResponse } from '../../dtos/tgw/update-transaction-response';
import { UpdateTransactionRequest } from '../../dtos/tgw/update-transaction-request';
import { BaseResponse } from '../../dtos/tgw/base-response';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TransactionHistoryRequest } from '../../dtos/tgw/transaction-history-request';
import { TransactionHistoryResponse } from '../../dtos/tgw/transaction-history-response';
import { CashTransactionRequest } from '../../dtos/tgw/cash-transaction-request';
import { CashTransactionResponse } from '../../dtos/tgw/cash-transaction-response';
import { CheckTransactionResponse } from '../../dtos/tgw/check-transaction-response';
import { CheckTransactionRequest } from '../../dtos/tgw/check-transaction-request';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TgwTransactionService {
  baseApiUrl = () => environment.tgwBaseApiUrl + "/transaction";

  constructor(
    private http: HttpClient,
  ) { }

  transactionHistory(request: TransactionHistoryRequest): Observable<BaseResponse<TransactionHistoryResponse>> {
    return this.http.post<BaseResponse<TransactionHistoryResponse>>(this.baseApiUrl() + "/history", request);
  }

  transactionErrorHistory(request: TransactionErrorHistoryRequest): Observable<BaseResponse<TransactionErrorHistoryResponse>> {
    return this.http.post<BaseResponse<TransactionErrorHistoryResponse>>(this.baseApiUrl() + "/transaction-error-history", request);
  }

  updateNotes(transId: number, notes: string): Observable<BaseResponse<UpdateTransactionResponse>> {
    const request: UpdateTransactionRequest = {
      transId: transId,
      params: {
        note: notes
      }
    }
    return this.http.post<BaseResponse<UpdateTransactionResponse>>(this.baseApiUrl() + "/updateTransaction", request);
  }

  sendTransactionVoucher(transId: number, email: string): Observable<BaseResponse<SendTransactionVoucherResponse>> {
    const request: SendTransactionVoucherRequest = {
      transId: transId,
      recipients: [email]
    }
    return this.http.post<BaseResponse<SendTransactionVoucherResponse>>(this.baseApiUrl() + "/emailUserTransactionVoucher", request);
  }

  cashTransaction(request: CashTransactionRequest): Observable<BaseResponse<CashTransactionResponse>> {
    return this.http.post<BaseResponse<CashTransactionResponse>>(this.baseApiUrl() + "/cash-transaction", request);
  }

  checkTransaction(request: CheckTransactionRequest): Observable<BaseResponse<CheckTransactionResponse>> {
    return this.http.post<BaseResponse<CheckTransactionResponse>>(this.baseApiUrl() + "/check-transaction", request);
  }
}
