<div [dir]="'dir' | translate">
    <mat-dialog-content #dialogContent>
        <h1 class="title" mat-dialog-title translate>
            <mat-icon>lock_reset</mat-icon>&nbsp;
            <label translate>forgot_password.title</label>
        </h1>
        <div class="msg" translate>forgot_password.msg</div>
        <div>
            <mat-form-field appearance="fill">
                <mat-label translate>auth.username</mat-label>
                <input matInput type="text" #username="ngModel" [(ngModel)]="data.username" required>
                <mat-error *ngIf="username.hasError('required')" translate>
                    errors.required_field
                </mat-error>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field appearance="fill">
                <mat-label translate>auth.company_id</mat-label>
                <input matInput type="text" #companyId="ngModel" [(ngModel)]="data.companyId" required
                    [pattern]="companyIdRule">
                <mat-error *ngIf="companyId.hasError('required')" translate>
                    errors.required_field
                </mat-error>
                <mat-error *ngIf="companyId.hasError('pattern')" translate>
                    errors.please_enter_digits_only
                </mat-error>
            </mat-form-field>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-stroked-button color="primary" (click)="onCancel()"><label translate>dialog.cancel</label></button>
        <button mat-flat-button color="primary" (click)="onSubmit()" cdkFocusInitial>
            <label translate>dialog.submit</label></button>
    </mat-dialog-actions>
</div>