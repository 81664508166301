import { Constants } from '../../infrastructure/constants';
import { ForgotPasswordResponse } from '../../dtos/tgw/forgot-password-response';
import { ForgotPasswordRequest } from '../../dtos/tgw/forgot-password-request';
import { ChangePasswordRequest } from '../../dtos/tgw/change-password-request';
import { ChangePasswordResponse } from '../../dtos/tgw/change-password-response';
import { CreateAccessTokenRequest } from '../../dtos/tgw/create-access-token-request';
import { BaseResponse } from '../../dtos/tgw/base-response';
import { CreateAccessTokenResponse } from '../../dtos/tgw/create-access-token-response';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TgwSecurityService {
  baseApiUrl = () => environment.tgwBaseApiUrl + "/security";

  constructor(
    private http: HttpClient,
  ) { }

  createAccessToken(user: string, pass: string): Observable<BaseResponse<CreateAccessTokenResponse>> {
    const request: CreateAccessTokenRequest = {};
    const options = {
      headers: {
        'Authorization': 'Basic ' + btoa(`${user}:${pass}`),
        ...Constants.getLoadingOffKeyObj(),
        ...Constants.getNotifyErrorOffKeyObj()
      }
    };
    return this.http.post<BaseResponse<CreateAccessTokenResponse>>(this.baseApiUrl() + "/access-token", request, options);
  }

  changePassword(user: string, pass: string, newPass: string): Observable<BaseResponse<ChangePasswordResponse>> {
    const request: ChangePasswordRequest = { newPassword: newPass };
    const options = {
      headers: { 'Authorization': 'Basic ' + btoa(`${user}:${pass}`) }
    };
    return this.http.post<BaseResponse<ChangePasswordResponse>>(this.baseApiUrl() + "/change-password", request, options);
  }

  forgotPassword(username: string, companyId: string): Observable<BaseResponse<ForgotPasswordResponse>> {
    const request: ForgotPasswordRequest = { username, companyId };
    return this.http.post<BaseResponse<ChangePasswordResponse>>(this.baseApiUrl() + "/forgot-password", request);
  }
}
