export enum PanEntryModeValues {
    MAGNETIC = "MAGNETIC",
    MAGNETIC_CONTACTLESS = "MAGNETIC_CONTACTLESS",
    EMV_CONTACTLESS = "EMV_CONTACTLESS",
    MAGNETIC_MOBILE_CONTACTLESS = "MAGNETIC_MOBILE_CONTACTLESS",
    EMV_MOBILE_CONTACTLESS = "EMV_MOBILE_CONTACTLESS",
    EMV_CONTACT = "EMV_CONTACT",
    PHONE = "PHONE",
    SIGNATURE_ONLY = "SIGNATURE_ONLY",
    INTERNET = "INTERNET",
    FALLBACK = "FALLBACK",
    EMPTY_CANDIDIATE_LIST = "EMPTY_CANDIDIATE_LIST"
};