import { environment } from './../../../../environments/environment';
import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  yearOfDevelopment = 2022;
  currentYear = (new Date()).getFullYear();
  readonly websiteVersion: string = environment.appVersion;
}
