import { CookieService } from './../../../services/cookie.service';
import { AuthService } from './../../../services/auth.service';
import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  userName: string = this.authService.getCurrentUser()?.username;
  @Output() onMenuClick = new EventEmitter();

  constructor(
    private authService: AuthService,
    private router: Router,
    private cookieService: CookieService
  ) {
  }

  menuClick() {
    this.onMenuClick.emit();
  }

  navigate(link: string) {
    this.router.navigateByUrl(link);
  }

  clearCache() {
    localStorage.clear();
    sessionStorage.clear();
    this.cookieService.deleteAllCookies();
    this.logout();
  }

  logout() {
    this.authService.logout(null);
  }
}
