<div [dir]="'dir' | translate">
    <mat-dialog-content #dialogContent>
        <h1 class="title" mat-dialog-title translate>
            <mat-icon>password</mat-icon>&nbsp;
            <label translate [translateParams]="{'username':data.username}">password_expired.title</label>
        </h1>
        <div class="msg" translate>password_expired.msg</div>
        <div>
            <mat-form-field appearance="fill">
                <mat-label translate>auth.username</mat-label>
                <input matInput type="text" [(ngModel)]="data.username" #username="ngModel" name="tgw_mp_username"
                    readonly required>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field appearance="fill">
                <mat-label translate>auth.new_password</mat-label>
                <input matInput [type]="passwordHide ? 'password' : 'text'" #newPassword="ngModel"
                    [(ngModel)]="data.newPassword" required [pattern]="merchantPasswordRule">
                <button mat-icon-button matSuffix (click)="passwordHide = !passwordHide"
                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="passwordHide">
                    <mat-icon>{{passwordHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                <mat-error *ngIf="newPassword.hasError('required')" translate>
                    auth.errors.new_password_is_required
                </mat-error>
                <mat-error *ngIf="newPassword.hasError('pattern')" translate>
                    auth.errors.new_password_policy_error
                </mat-error>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field appearance="fill">
                <mat-label translate>auth.confirm_new_password</mat-label>
                <input matInput [type]="confirmPasswordHide ? 'password' : 'text'" #confirmNewPassword="ngModel"
                    [(ngModel)]="data.confirmNewPassword" required [appEqual]="newPassword.value">
                <button mat-icon-button matSuffix (click)="confirmPasswordHide = !confirmPasswordHide"
                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="confirmPasswordHide">
                    <mat-icon>{{confirmPasswordHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                <mat-error *ngIf="confirmNewPassword.hasError('required')||confirmNewPassword.hasError('equal')"
                    translate>
                    auth.errors.passwords_does_not_match
                </mat-error>
                <!-- <div *ngIf="confirmNewPassword.value !== newPassword.value" class="error-container" translate>
                    auth.errors.passwords_does_not_match
                </div> -->
            </mat-form-field>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-stroked-button color="primary" (click)="onCancel()"><label translate>dialog.cancel</label></button>
        <button mat-flat-button color="primary" (click)="onSubmit()" cdkFocusInitial>
            <label translate>dialog.submit</label></button>
    </mat-dialog-actions>
</div>