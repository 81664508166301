import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: '[appEqual]',
  providers: [{ provide: NG_VALIDATORS, useExisting: EqualValidator, multi: true }]
})
export class EqualValidator implements Validator {
  @Input('appEqual') equal = '';

  validate(control: AbstractControl): ValidationErrors | null {
    let pass = control.value;
    return pass === this.equal ? null : { equal: true }
  }
}
