import { Direction } from '@angular/cdk/bidi';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { filter, map, mergeMap, Subscription } from 'rxjs';
import { PageTitleService } from './../../../services/page-title.service';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss']
})
export class RootComponent implements OnInit {
  onLangChangeSubscribe: Subscription;
  myDir: Direction | "auto" = "auto";

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private pageTitleService: PageTitleService
  ) { }

  ngOnInit(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(map(route => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }))
      .pipe(filter(route => route.outlet === 'primary'))
      .pipe(mergeMap(route => route.data))
      .subscribe(async (event) => {
        this.pageTitleService.setPageTitleKey(event['title']);
      });
  }
}
