import { InstallmentsDto } from "./installments-dto";
import { TransactionStatusValues } from "./transaction-status-values";
import { TransactionTypeValues } from "./transaction-type-values";

export class TransactionHistoryObject {
    //global data
    transId: number;
    posTransId: number;
    transactionType: TransactionTypeValues;
    merchantId: number;
    timestamp: string;
    amount: number;
    currency: string;
    status: TransactionStatusValues;
    uid?: string;
    last4: string;
    cardName: string;
    creditTerms: string;
    installments?: InstallmentsDto;
    j5: boolean;
    authorizationNumber?: string;
    exp: string;
    voucherNumber?: string;
    referenceNumber?: string;
    acquirer?: string;

    //check data
    bankNumber: number;
    branchNumber: number;
    accountNumber: number;
    checkNumber: number;

    //other data
    signatureLink?: string;
    notes?: string;
}

export class TransactionHistoryResponse {
    total: number;
    offset: number;
    limit: number;
    transactions: TransactionHistoryObject[];
}
