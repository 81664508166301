import { Constants } from './../../../infrastructure/constants';
import { ILanguageItem } from '../../../infrastructure/interfaces/language-item';
import { TranslateConfigService } from './../../../services/translate-config.service';
import { Component } from '@angular/core';

@Component({
  selector: 'app-select-website-language',
  templateUrl: './select-website-language.component.html',
  styleUrls: ['./select-website-language.component.scss']
})
export class SelectWebsiteLanguageComponent {
  languages = Constants.languages;

  public selectedLanguage: ILanguageItem = this.languages.find(x => x.value === this.translateConfigService.getCurrentLang()) ?? this.languages[0];

  constructor(private translateConfigService: TranslateConfigService) { }

  onLanguageChange(lang: ILanguageItem) {
    this.selectedLanguage = lang;
    this.translateConfigService.changeLanguage(this.selectedLanguage.value);
  }
}
