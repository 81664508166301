<div [dir]="'dir' | translate">
    <mat-dialog-content #dialogContent>
        <h1 class="title" mat-dialog-title translate>
            <mat-icon>campaign</mat-icon>&nbsp;
            <label translate>transaction_notes_dialog.title</label>
        </h1>
        <div class="loading-container" *ngIf="loading">
            <mat-spinner></mat-spinner>
            <label translate>dialog.please_wait</label>
        </div>
        <ng-container *ngIf="!loading">

            <mat-form-field appearance="fill">
                <mat-label translate>payment.trans_id</mat-label>
                <input matInput type="text" #transId="ngModel" [(ngModel)]="data.transId" readonly min="0" required>
                <mat-error *ngIf="transId.hasError('required')" translate>
                    errors.required_field
                </mat-error>
                <mat-error *ngIf="transId.hasError('min')" translate>
                    payment.invalid_transaction_id
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill" class="notes-container">
                <mat-label translate>payment.notes</mat-label>
                <textarea matInput type="text" #notes="ngModel" [(ngModel)]="data.notes"></textarea>
            </mat-form-field>

        </ng-container>
    </mat-dialog-content>
    <mat-dialog-actions align="end" *ngIf="!loading">
        <button mat-stroked-button color="primary" type="button" (click)="onCancel()">
            <label translate>dialog.cancel</label>
        </button>
        <button mat-flat-button color="primary" (click)="onSubmit()" cdkFocusInitial>
            <label translate [innerHtml]="loading?'dialog.please_wait':'dialog.submit'"></label>
        </button>
    </mat-dialog-actions>
</div>