import { AnonymousGuard } from './infrastructure/guards/anonymous.guard';
import { AuthGuard } from './infrastructure/guards/auth.guard';
import { ChangePasswordComponent } from './components/auth-area/change-password/change-password.component';
import { HomeComponent } from './components/home-area/home/home.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/auth-area/login/login.component';
import { LayoutComponent } from './components/layout-area/layout/layout.component';
import { TransactionsListComponent } from './components/transactions-area/transactions-list/transactions-list.component';
import { TransactionsErrorsComponent } from './components/transactions-area/transactions-errors/transactions-errors.component';
import { RecurringOrdersComponent } from './components/recurring-orders-area/recurring-orders/recurring-orders.component';
import { ProfileComponent } from './components/auth-area/user-profile/user-profile.component';
import { TransactionsDepositComponent } from './components/deposit-area/transactions-deposit/transactions-deposit.component';
import { DepositReportsComponent } from './components/deposit-area/deposit-reports/deposit-reports.component';

const routes: Routes = [
  {
    path: 'login',
    canActivate: [AnonymousGuard],
    component: LoginComponent,
    data: {
      title: "system_name"
    }
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'home',
        component: HomeComponent,
        data: {
          title: "system_name"
        }
      },
      {
        path: 'transactions',
        component: TransactionsListComponent,
        data: {
          title: "menu.transactions"
        }
      },
      {
        path: 'transactionsErrors',
        component: TransactionsErrorsComponent,
        data: {
          title: "menu.transactions_errors"
        }
      },
      {
        path: 'recurringOrders',
        component: RecurringOrdersComponent,
        data: {
          title: "menu.recurring_orders"
        }
      },
      {
        path: 'depositReports',
        component: DepositReportsComponent,
        data: {
          title: "menu.deposit_reports"
        }
      },
      {
        path: 'transactionsDeposit',
        component: TransactionsDepositComponent,
        data: {
          title: "menu.transactions_deposit"
        }
      },
      {
        path: 'changePassword',
        component: ChangePasswordComponent,
        data: {
          title: "header.user_menu.change_password"
        }
      },
      {
        path: 'profile',
        component: ProfileComponent,
        data: {
          title: "header.user_menu.profile_info"
        }
      },
      {
        path: '**',
        redirectTo: '/login', pathMatch: 'full'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
