import { Component } from '@angular/core';
import { PageTitleService } from './../../../services/page-title.service';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss']
})
export class PageTitleComponent {
  constructor(
    public pageTitleService: PageTitleService
  ) { }
}
