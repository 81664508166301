import { TransactionHistoryRequest } from '../../../dtos/tgw/transaction-history-request';
import { DialogService } from '../../../services/dialog.service';
import { TranslatePipe } from '@ngx-translate/core';
import { TgwTransactionService } from '../../../services/tgw/tgw-transaction.service';
import { TransactionHistoryObject } from '../../../dtos/tgw/transaction-history-response';
import { ITableData } from '../../../infrastructure/interfaces/table/table-data.interface';
import { Component, OnInit } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions-list.component.html',
  styleUrls: ['./transactions-list.component.scss']
})
export class TransactionsListComponent implements OnInit {
  historyFilter: TransactionHistoryRequest = {};

  constructor(
    private tgwTransactionService: TgwTransactionService,
    private currencyPipe: CurrencyPipe,
    private translatePipe: TranslatePipe,
    private dialogService: DialogService
  ) { }


  tableData: ITableData<TransactionHistoryObject> = {
    columns: [
      { name: 'transId', headerText: 'payment.trans_id', sortable: true },
      {
        name: 'amount', headerText: 'payment.amount', sortable: true, sendRowToConverter: true,
        converter: (row: TransactionHistoryObject) => {
          return this.currencyPipe.transform(row?.amount, row.currency, 'symbol');
        }
      },
      {
        name: 'transactionType', headerText: 'payment.transaction_type', sortable: true, sendRowToConverter: true,
        converter: (row: TransactionHistoryObject) => {
          return this.translatePipe.transform(`payment.transaction_type_values.${row?.transactionType?.toLowerCase()}`);
        }
      },
      {
        name: 'status', headerText: 'status', sortable: true, sendRowToConverter: true,
        converter: (row: TransactionHistoryObject) => {
          return this.translatePipe.transform(`payment.status_values.${row?.status?.toLowerCase()}`);
        }
      },
      { name: 'acquirer', headerText: 'payment.acquirer', sortable: true },
      { name: 'cardName', headerText: 'payment.card_name', sortable: true }, 
      { name: 'last4', headerText: 'payment.last_4', sortable: true },
      { name: 'voucherNumber', headerText: 'payment.voucher_number', sortable: true },
      { name: 'referenceNumber', headerText: 'payment.reference_number', sortable: true },
      { name: 'timestamp', headerText: 'payment.time', sortable: true },
    ],
    getRowIcons(row: TransactionHistoryObject) {
      const icons: string[] = [];
      if (row.signatureLink)
        icons.push('draw');
      return icons;
    },
    items: [],
    filter: true,
    rowActions: [
      {
        infoByRow: {
          isVisible: (row: TransactionHistoryObject) => true,
          text: (row: TransactionHistoryObject) => null,
          color: (row: TransactionHistoryObject) => row.notes ? "#FF4081" : "black",
          icon: (row: TransactionHistoryObject) => row.notes ? "edit_note" : "note_add",
          toolTip: (row: TransactionHistoryObject) => row.notes ? row.notes : this.translatePipe.transform(`add_note`)
        },
        translateToolTip: false,
        onClick: (row: TransactionHistoryObject) => {
          this.dialogService.openTransactionNotesDialog({
            transaction: row,
            updateSuccessCallBack: (transId: number, newNotes: string) => {
              const index = this.tableData.items.findIndex(x => x.transId === transId);
              if (index >= 0) {
                this.tableData.items[index].notes = newNotes;
              }
            }
          });
        }
      },
      {
        text: null,
        color: "#3F51B5",
        icon: "forward_to_inbox",
        toolTip: `payment.send_voucher`,
        translateToolTip: true,
        onClick: (row: TransactionHistoryObject) => {
          this.dialogService.openTransactionSendVoucherDialog({ transId: row?.transId });
        }
      },
      {
        text: null,
        color: "#3F51B5",
        icon: "more_vert",
        toolTip: `details`,
        translateToolTip: true,
        onClick: (row: TransactionHistoryObject) => {
          this.dialogService.openTransactionDetailsDialog(row);
        }
      }
    ],
    onRefresh: () => this.refresh(),
    headerActions: [
      {
        text: 'payment.new_transaction',
        color: '#3F51B5',
        icon: 'add',
        onClick: () => {
          this.dialogService.openNewTransactionDialog({
            successCallBack: () => {
              this.refresh();
            }
          });
        }
      },
      {
        text: 'filter',
        color: '',
        icon: 'filter_alt',
        onClick: () => {
          this.dialogService.openTransactionHistoryFilterDialog({
            request: this.historyFilter,
            callBack: (newFilter: TransactionHistoryRequest) => {
              this.historyFilter = newFilter;
              this.refresh();
            }
          });
        }
      }
    ],
    getRowColor: (row: TransactionHistoryObject) => {
      switch (row.status?.toLowerCase() ?? '') {
        case 'pending':
          return "#CC9B00";
        case 'completed':
          return "#00B050";
        case 'canceled':
          return "#FF0000";
      }
      return "black";
    }
  }

  ngOnInit(): void {
    this.refresh();
  }

  refresh(): void {
    this.historyFilter.limit = 200;
    this.tgwTransactionService.transactionHistory(this.historyFilter)
      .subscribe(response => {
        this.tableData.items = response?.data?.transactions ?? [];
      });
  }

}
