import { DepositReportItem } from './../../../dtos/tgw/deposit-report-response';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-report-viewer-dialog',
  templateUrl: './report-viewer-dialog.component.html',
  styleUrls: ['./report-viewer-dialog.component.scss']
})
export class ReportViewerDialogComponent {
  loading: boolean = false;
  data = {
    created: this.requestData?.created,
    report: this.requestData?.report ?? ''
  };

  constructor(
    private dialogRef: MatDialogRef<ReportViewerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public requestData: DepositReportItem,
  ) { }

  onClose() {
    this.dialogRef.close();
  }
}
