import { Injectable } from '@angular/core';
import { ActiveToast, IndividualConfig, ToastrService } from 'ngx-toastr';
import { TranslateConfigService } from './translate-config.service';

@Injectable({
  providedIn: 'root'
})
export class NotifyService {

  constructor(
    private toastr: ToastrService
  ) { }

  private getOverrideIndividualConfigDefault(): Partial<IndividualConfig> {
    return {
      messageClass: `toastr-message-${TranslateConfigService.getDir()}`
    };
  }

  /** show exception */
  public exception(err: any): void {
    const errorMsg = this.getErrorMessage(err);
    this.error(errorMsg);
  }

  private getErrorMessage(err: any): string {
    console.log("err ", err);

    if (typeof err === "string") {
      return err;
    }

    if (err.error && Array.isArray(err.error) && typeof err.error[0] === "string") {
      let msg = "";
      err.error.forEach((element: string) => {
        msg += element + "\n";
      });
      return msg;
    }

    if (err.error && typeof err.error === "string") {
      return err.error;
    }
    if (err?.error?.errorMessage && typeof err.error.errorMessage === "string") {
      return err.error.errorMessage;
    }

    if (typeof err.response?.data === "string") {
      return err.response.data;
    }

    if (Array.isArray(err.response?.data)) {
      return err.response.data[0];
    }

    if (typeof err.message === "string") {
      return err.message;
    }

    console.error(err);
    return "Some error occurred, please try again";
  }

  /** show toast */
  show(message?: string, title?: string, override?: Partial<IndividualConfig>, type?: string): ActiveToast<any> {
    return this.toastr.show(message, title, override ?? this.getOverrideIndividualConfigDefault(), type);
  }

  /** show successful toast */
  success(message?: string, title?: string, override?: Partial<IndividualConfig>): ActiveToast<any> {
    return this.toastr.success(message, title, override ?? this.getOverrideIndividualConfigDefault());
  }

  /** show error toast */
  error(message?: string, title?: string, override?: Partial<IndividualConfig>): ActiveToast<any> {
    return this.toastr.error(message, title, override ?? this.getOverrideIndividualConfigDefault());
  }

  /** show info toast */
  info(message?: string, title?: string, override?: Partial<IndividualConfig>): ActiveToast<any> {
    return this.toastr.info(message, title, override ?? this.getOverrideIndividualConfigDefault());
  }

  /** show warning toast */
  warning(message?: string, title?: string, override?: Partial<IndividualConfig>): ActiveToast<any> {
    return this.toastr.warning(message, title, override ?? this.getOverrideIndividualConfigDefault());
  }

}
