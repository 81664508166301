import { SpinnerOverlayComponent } from './../components/layout-area/spinner-overlay/spinner-overlay.component';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SpinnerOverlayService {
  private static overlayRef: OverlayRef | null = null;
  private static attachCounter = 0;
  constructor(
    private overlay: Overlay
  ) { }

  public show() {
    // Returns an OverlayRef (which is a PortalHost)

    if (!SpinnerOverlayService.overlayRef) {
      SpinnerOverlayService.overlayRef = this.overlay.create();
    }

    // Create ComponentPortal that can be attached to a PortalHost
    if (1 === ++SpinnerOverlayService.attachCounter) {
      const spinnerOverlayPortal = new ComponentPortal(SpinnerOverlayComponent);
      SpinnerOverlayService.overlayRef.attach(spinnerOverlayPortal); // Attach ComponentPortal to PortalHost
    }
  }

  public hide() {
    if (!!SpinnerOverlayService.overlayRef && 0 === --SpinnerOverlayService.attachCounter) {
      SpinnerOverlayService.overlayRef.detach();
    }
  }
}