<div class="layout-container">
    <aside [ngStyle]="menuStyles">
        <app-menu></app-menu>
    </aside>
    <header [ngStyle]="headerMainFooterStyles">
        <app-header (onMenuClick)="menuClick()"></app-header>
    </header>
    <main [ngStyle]="headerMainFooterStyles">
        <app-page-title></app-page-title>
        <div>
            <router-outlet></router-outlet>
        </div>
    </main>
    <footer [ngStyle]="headerMainFooterStyles">
        <app-footer></app-footer>
    </footer>
</div>