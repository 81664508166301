import { TgwEmvService } from '../../../services/tgw/tgw-emv.service';
import { AshFullRequest, InstallmentsDto } from './../../../dtos/tgw/ash-full-request';
import { BaseResponse } from './../../../dtos/tgw/base-response';
import { CashTransactionResponse } from './../../../dtos/tgw/cash-transaction-response';
import { TranslateConfigService } from './../../../services/translate-config.service';
import { NotifyService } from './../../../services/notify.service';
import { NewTransactionDialogData } from './../../../dtos/dialogs/new-transaction-dialog-data';
import { TgwApiUtils } from './../../../utils/tgw-api-utils';
import { TgwTransactionService } from '../../../services/tgw/tgw-transaction.service';
import { CashTransactionRequest } from './../../../dtos/tgw/cash-transaction-request';
import { NgModel } from '@angular/forms';
import { TransactionTypeValues } from './../../../dtos/tgw/transaction-type-values';
import { Constants } from './../../../infrastructure/constants';
import { Component, Inject, ViewChild, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CheckTransactionResponse } from './../../../dtos/tgw/check-transaction-response';
import { MtiValues } from './../../../dtos/tgw/mti_values';
import { PanEntryModeValues } from './../../../dtos/tgw/pan_entry_mode_values';
import { CreditTypeValues } from './../../../dtos/tgw/credit-type-values';
import { CreditTermsValues } from './../../../dtos/tgw/credit-terms-values';
import { AuthorizationCodeManpikValues } from './../../../dtos/tgw/authorization_code_manpik_values';
import { MatMonthView } from '@angular/material/datepicker';
interface IGlobalData {
  amount: number;
  currencyCode: number;
  notes?: string;
  email?: string;
}
interface ICheckData {
  checkNumber: number;
  bankNumber: number;
  branchNumber: number;
  accountNumber: number;
}
interface ICreditData {
  terms: CreditTermsValues;
  cardNumber: string;
  expiryValue: string;
  expiry: {
    year: number;
    month: number;
  };
  cvv?: string;
  customerId?: string;
  installment?: InstallmentsDto;
  authorizationNo?: string;
  j5: boolean;
}

@Component({
  selector: 'app-new-transaction-dialog',
  templateUrl: './new-transaction-dialog.component.html',
  styleUrls: ['./new-transaction-dialog.component.scss'],
})
export class NewTransactionDialogComponent implements OnInit {
  zeroPad = (num: number, places: number) => String(num).padStart(places, '0');
  range = (start: number, stop: number, step: number) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));
  readonly LengthOfYears = 15;
  transactionTypeValues = Constants.TransactionTypeValues;
  creditTermsValues = CreditTermsValues;
  transactionType: TransactionTypeValues = TransactionTypeValues.CREDIT;
  readonly currentYear = (new Date()).getFullYear();
  readonly currentMonth = (new Date()).getMonth() + 1;
  months: number[] = this.range(1, 12, 1);
  years: number[] = this.range(this.currentYear, this.currentYear + this.LengthOfYears, 1);

  global_data: IGlobalData = {
    amount: null,
    currencyCode: 376,
    notes: ''
  };

  credit_data: ICreditData = {
    terms: CreditTermsValues.REGULAR,
    cardNumber: '',
    expiryValue: '',
    expiry: {
      year: null,
      month: null
    },
    cvv: '',
    customerId: '',
    installment: {
      count: 2,
      amount: null,
      firstAmount: null
    },
    authorizationNo: null,
    j5: false,
  }

  //global elements
  @ViewChild('amount') amount: NgModel;
  @ViewChild('email') email: NgModel;

  //check elements
  @ViewChild('bankNumber') bankNumber: NgModel;
  @ViewChild('branchNumber') branchNumber: NgModel;
  @ViewChild('accountNumber') accountNumber: NgModel;
  @ViewChild('checkNumber') checkNumber: NgModel;

  //credit elements
  @ViewChild('creditTerms') creditTerms: NgModel;
  @ViewChild('cardNumber') cardNumber: NgModel;
  @ViewChild('expiryYear') expiryYear: NgModel;
  @ViewChild('expiryMonth') expiryMonth: NgModel;
  @ViewChild('expiry') expiry: NgModel;
  @ViewChild('cvv') cvv: NgModel;
  @ViewChild('customerId') customerId: NgModel;
  @ViewChild('authorizationNo') authorizationNo: NgModel;
  @ViewChild('installmentCount') installmentCount: NgModel;
  @ViewChild('installmentFirstAmount') installmentFirstAmount: NgModel;
  @ViewChild('installmentAmount') installmentAmount: NgModel;

  constructor(
    private dialogRef: MatDialogRef<NewTransactionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public requestData: NewTransactionDialogData,
    private tgwTransactionService: TgwTransactionService,
    private tgwEmvService: TgwEmvService,
    private notify: NotifyService,
    private translateConfigService: TranslateConfigService
  ) { }

  ngOnInit(): void {
    this.setFirstAmountAndAdditionalAmount();
  }

  onCancel() {
    this.dialogRef.close();
  }

  async onPaymentResponseCompleted(response: BaseResponse<CashTransactionResponse> | BaseResponse<CheckTransactionResponse>) {
    try {
      if (TgwApiUtils.TgwResponseIsSuccess(response)) {
        if (this.requestData.successCallBack) {
          this.requestData.successCallBack();
          this.notify.success(await this.translateConfigService.getValueAsync('new_transaction_dialog.successfully_msg', {
            transId: response?.data?.transId
          }));
          if (this.global_data.email) {
            const voucherResp = await this.tgwTransactionService.sendTransactionVoucher(response?.data?.transId, this.global_data.email).toPromise();
            if (TgwApiUtils.TgwResponseIsSuccess(voucherResp) && voucherResp?.data?.result) {
              this.notify.success(await this.translateConfigService.getValueAsync('transaction_send_voucher_dialog.the_email_was_sent_successfully'));
            }
          }
        }
      }
    } catch (err: any) { }
    finally { this.dialogRef.close(); }
  }

  validation(): boolean {
    let result = true;

    //global validation
    if (this.amount.invalid) { this.amount.control.markAsTouched(); result = false; }
    if (this.email.invalid) { this.email.control.markAsTouched(); result = false; }

    switch (this.transactionType) {

      case TransactionTypeValues.CREDIT:
        if (this.creditTerms.invalid) { this.creditTerms.control.markAsTouched(); result = false; }
        if (this.cardNumber.invalid) { this.cardNumber.control.markAsTouched(); result = false; }
        if (this.expiryYear.invalid) { this.expiryYear.control.markAsTouched(); result = false; }
        if (this.expiryMonth.invalid) { this.expiryMonth.control.markAsTouched(); result = false; }
        if (this.expiry.invalid) { this.expiry.control.markAsTouched(); result = false; }
        if (this.cvv.invalid) { this.cvv.control.markAsTouched(); result = false; }
        if (this.customerId.invalid) { this.customerId.control.markAsTouched(); result = false; }
        if (this.authorizationNo.invalid) { this.authorizationNo.control.markAsTouched(); result = false; }

        if (this.credit_data.terms === CreditTermsValues.INSTALLMENTS || this.credit_data.terms === CreditTermsValues.CREDIT_INSTALLMENTS) {
          //check for installment or credit installment transaction
          if (this.installmentCount.invalid) { this.installmentCount.control.markAsTouched(); result = false; }
        }
        if (this.credit_data.terms === CreditTermsValues.INSTALLMENTS) {
          //check for installment transaction
          if (this.installmentFirstAmount.invalid) { this.installmentFirstAmount.control.markAsTouched(); result = false; }
          if (this.installmentAmount.invalid) { this.installmentAmount.control.markAsTouched(); result = false; }
        }
        break;

    }
    return result;
  }

  onSubmit() {
    if (!this.validation())
      return;

    switch (this.transactionType) {
      case TransactionTypeValues.CREDIT:
        this.onSubmitCredit();
        break;
    }
  }

  async onSubmitCredit() {
    try {
      const isRefund = this.global_data.amount < 0;

      let installmentObj: InstallmentsDto = null;
      if (this.credit_data.terms === CreditTermsValues.INSTALLMENTS) {
        installmentObj = {
          count: this.credit_data.installment.count - 1, //payments count -1 for shva installments transaction
          firstAmount: this.credit_data.installment.firstAmount,
          amount: this.credit_data.installment.amount
        };
      }
      if (this.credit_data.terms === CreditTermsValues.CREDIT_INSTALLMENTS) {
        installmentObj = {
          count: this.credit_data.installment.count
        };
      }

      let authorizationCodeManpikValue = null;
      if (this.credit_data.authorizationNo)
        authorizationCodeManpikValue = AuthorizationCodeManpikValues.PHONE_APPROVED_TRANSACTION;

      const request: AshFullRequest = {
        amount: Math.abs(this.global_data.amount),
        currencyCode: this.global_data.currencyCode,
        params: {
          note: this.global_data.notes
        },
        mti: MtiValues.REGULAR,
        panEntryMode: PanEntryModeValues.PHONE,
        type: isRefund ? CreditTypeValues.REFUND : CreditTypeValues.CHARGE,
        terms: this.credit_data.terms,
        manualFields: {
          cardNumber: { value: this.credit_data.cardNumber },
          expiry: {
            value: this.credit_data.expiryValue
          },
          customerId: { value: this.credit_data.customerId },
          cvv: { value: this.credit_data.cvv },
        },
        installments: installmentObj,
        authorizationNo: this.credit_data.authorizationNo,
        jParam: this.credit_data.j5 ? 5 : 4,
        authorizationCodeManpik: authorizationCodeManpikValue
      };
      const response = await this.tgwEmvService.creditAshFullTransaction(request).toPromise();
      await this.onPaymentResponseCompleted(response);
    }
    catch { }
  }

  getFirstAmountMin(): number {
    return this.global_data.amount >= 0 ? 0 : this.global_data.amount;
  }

  getFirstAmountMax(): number {
    return this.global_data.amount >= 0 ? this.global_data.amount : 0;
  }

  getAdditionalAmountMin(): number {
    return this.global_data.amount >= 0 ? 0 : this.global_data.amount;
  }

  getAdditionalAmountMax(): number {
    return this.global_data.amount >= 0 ? this.global_data.amount : 0;
  }


  setFirstAmountAndAdditionalAmount() {
    if (this.credit_data.terms === CreditTermsValues.INSTALLMENTS) {
      let amount = this.global_data.amount;
      let payments = this.credit_data.installment.count;
      let additionalAmount = Math.floor(amount / payments);
      let firsAmount = ((amount * 100) - (((payments - 1) * additionalAmount) * 100)) / 100;
      this.credit_data.installment.firstAmount = firsAmount;
      this.credit_data.installment.amount = additionalAmount;
    }
  }

  onAdditionalAmountChange() {
    if (this.credit_data.terms === CreditTermsValues.INSTALLMENTS) {
      let amount = this.global_data.amount;
      let payments = this.credit_data.installment.count;
      let additionalAmount = this.credit_data.installment.amount;
      let firsAmount = ((amount * 100) - (((payments - 1) * additionalAmount) * 100)) / 100;
      this.credit_data.installment.firstAmount = firsAmount;
    }
  }

  onFirstAmountChange() {
    if (this.credit_data.terms === CreditTermsValues.INSTALLMENTS) {
      const amount = this.global_data.amount;
      const payments = this.credit_data.installment.count;
      const firstAmount = this.credit_data.installment.firstAmount;
      const additionalAmount = (amount - firstAmount)/(payments -1);
      this.credit_data.installment.amount = additionalAmount;
    }
  }

  setExpiryValue() {
    let res = '';

    if (this.credit_data.expiry.year)
      res += this.credit_data.expiry.year.toString().slice(-2);

    if (this.credit_data.expiry.month)
      res += this.zeroPad(this.credit_data.expiry.month, 2);

    this.credit_data.expiryValue = res;
  }
}
