import { DialogService } from './../../../services/dialog.service';
import { TranslateConfigService } from './../../../services/translate-config.service';
import { TransactionHistoryObject } from './../../../dtos/tgw/transaction-history-response';
import { Constants } from './../../../infrastructure/constants';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-transaction-details-dialog',
  templateUrl: './transaction-details-dialog.component.html',
  styleUrls: ['./transaction-details-dialog.component.scss']
})
export class TransactionDetailsDialogComponent {
  transactionTypeValues = Constants.TransactionTypeValues;

  constructor(
    private dialogRef: MatDialogRef<TransactionDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TransactionHistoryObject,
    private translateConfigService: TranslateConfigService,
    private dialogService: DialogService
  ) { }

  onClose() {
    this.dialogRef.close();
  }

  onSendVoucher() {
    this.dialogRef.close();
    this.dialogService.openTransactionSendVoucherDialog({ transId: this.data.transId })
  }

  getCreditTermsValue(value: string): string {
    return this.translateConfigService.getValue(`payment.credit_terms_values.${value}`);
  }

  getTransactionTypeValue(value: string): string {
    return this.translateConfigService.getValue(`payment.transaction_type_values.${value}`);
  }

  getStatusValue(value: string): string {
    return this.translateConfigService.getValue(`payment.status_values.${value}`);
  }
}
