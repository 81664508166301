<div [dir]="'dir' | translate">
    <mat-dialog-content #dialogContent>
        <h1 class="title" mat-dialog-title translate>
            <mat-icon>receipt_long</mat-icon>&nbsp;
            <label translate>deposit_viewer.title</label>
        </h1>

        <mat-form-field appearance="fill">
            <mat-label translate>created_time</mat-label>
            <input matInput type="text" #transId="ngModel" [ngModel]="data.created|date:'yyyy-MM-dd HH:mm'" readonly>
        </mat-form-field>

        <mat-form-field appearance="fill" class="notes-container">
            <mat-label translate>report</mat-label>
            <textarea matInput rows="15" type="text" #notes="ngModel" [(ngModel)]="data.report" readonly></textarea>
        </mat-form-field>

    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-flat-button color="primary" (click)="onClose()" cdkFocusInitial>
            <mat-icon>close</mat-icon>&nbsp;
            <label translate>dialog.close</label>
        </button>
    </mat-dialog-actions>
</div>