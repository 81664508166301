import { InitXmlResponse } from '../../dtos/tgw/init-xml-response';
import { BaseResponse } from '../../dtos/tgw/base-response';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class TgwInitService {
  baseApiUrl = () => environment.tgwBaseApiUrl + "/init";

  constructor(
    private http: HttpClient,
  ) { }

  getInitXml(): Observable<BaseResponse<InitXmlResponse>> {
    return this.http.get<BaseResponse<InitXmlResponse>>(this.baseApiUrl() + "/getInitXml");
  }
}

