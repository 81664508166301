import { TranslateConfigService } from './../../../services/translate-config.service';
import { NotifyService } from './../../../services/notify.service';
import { TgwApiUtils } from './../../../utils/tgw-api-utils';
import { TgwSecurityService } from '../../../services/tgw/tgw-security.service';
import { Constants } from './../../../infrastructure/constants';
import { AuthService } from './../../../services/auth.service';
import { Component, OnInit, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators, NgModel } from '@angular/forms';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent {
  @ViewChild('changePasswordForm') changePasswordForm: ElementRef<HTMLFormElement>;
  @ViewChild('buttonState') buttonState: ElementRef<HTMLSpanElement>;
  changePassError = '';
  readonly merchantPasswordRule = Constants.merchantPasswordRule;
  loading: boolean = false;
  hide = {
    password: true,
    newPassword: true,
    confirmNewPassword: true
  }

  passwordsMatchValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    let pass = this?.changePassFormGroup?.get('newPassword')?.value;
    let confirmPass = this?.changePassFormGroup?.get('confirmNewPassword')?.value
    return pass === confirmPass ? null : { passwordsMatch: true }
  }

  changePassFormGroup: FormGroup = this.formBuilder.group({
    'username': new FormControl(this.authService.getCurrentUser()?.username, [Validators.required]),
    'password': new FormControl('', [Validators.required]),
    'newPassword': new FormControl('', [Validators.required, Validators.pattern(Constants.merchantPasswordRule)]),
    'confirmNewPassword': new FormControl('', [Validators.required, this.passwordsMatchValidator.bind(this)])
  });

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private tgwSecurityService: TgwSecurityService,
    private notify: NotifyService,
    private translateConfigService: TranslateConfigService
  ) { }

  async onSubmit(event: SubmitEvent) {
    event.preventDefault();
    if (this.loading || this.changePassFormGroup.invalid)
      return;

    try {
      const username = this.changePassFormGroup.controls['username'].value;
      const password = this.changePassFormGroup.controls['password'].value;
      const newPassword = this.changePassFormGroup.controls['newPassword'].value;
      const response = await this.tgwSecurityService.changePassword(username, password, newPassword).toPromise();
      if (TgwApiUtils.TgwResponseIsSuccess(response) && response?.data?.result) {
        this.notify.success(await this.translateConfigService.getValueAsync('auth.messages.password_changed_successfully'));
      }
    } catch { }
  }
}
