import { BaseResponse } from './../../dtos/tgw/base-response';
import { UserDetailsResponse } from './../../dtos/tgw/user-details-response';
import { UserDetailsRequest } from './../../dtos/tgw/user-details-request';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TgwUserService {
  baseApiUrl = () => environment.tgwBaseApiUrl + "/user";

  constructor(
    private http: HttpClient,
  ) { }

  getUserDetails(): Observable<BaseResponse<UserDetailsResponse>> {
    const request: UserDetailsRequest = {};
    return this.http.post<BaseResponse<UserDetailsResponse>>(this.baseApiUrl() + "/userDetails", request);
  }
}
