import { AuthService } from './../../services/auth.service';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpHeaders } from '@angular/common/http';
import { map, Observable } from 'rxjs';

@Injectable()
export class AddAuthorizationHeaderInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    if (request.url.endsWith(".json")) // action no needed on load translate items
      return next.handle(request);

    if (!request.headers.has("Authorization")) {
      const currentUser = this.authService.getCurrentUser();
      request = request.clone({
        setHeaders: {
          Authorization: 'Basic ' + btoa(`${currentUser?.username}:${currentUser?.accessToken}`)
        }
      });
    }
    return next.handle(request);
  }
}
