import { Router } from '@angular/router';
import { AuthService } from './../../services/auth.service';
import { Constants } from './../constants';
import { NotifyService } from '../../services/notify.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private notifyService: NotifyService,
    private authService: AuthService,
    private router: Router
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.endsWith(".json")) // action no needed on load translate items
      return next.handle(request);

    const notifyErrorOff = request.headers.has(Constants.notifyErrorOffKey);
    if (notifyErrorOff)
      request.headers.delete(Constants.notifyErrorOffKey);

    return next.handle(request).pipe(
      catchError((err: any) => {
        if (err?.error?.statusCode === 401)
          this.authService.logout(this.router.url)
        if (err && !notifyErrorOff) {
          this.notifyService.exception(err);
          console.error(err);
        }
        return throwError(err);
      })
    );
  }

}
