import { NgModule } from '@angular/core';
import { PortalModule } from '@angular/cdk/portal';
import { BrowserModule } from '@angular/platform-browser';

import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';

const matModules = [
  MatInputModule, MatSelectModule, MatFormFieldModule,
  MatButtonModule, MatIconModule, MatToolbarModule,
  MatMenuModule, MatDialogModule, MatProgressSpinnerModule,
  MatDatepickerModule, MatExpansionModule, MatNativeDateModule,
  MatSlideToggleModule, MatPaginatorModule, MatTableModule,
  MatSortModule, MatTooltipModule, MatCardModule
];

import { AppRoutingModule } from './app-routing.module';
import { LayoutComponent } from './components/layout-area/layout/layout.component';
import { LoginComponent } from './components/auth-area/login/login.component';
import { HeaderComponent } from './components/layout-area/header/header.component';
import { FooterComponent } from './components/layout-area/footer/footer.component';
import { MenuComponent } from './components/layout-area/menu/menu.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './components/home-area/home/home.component';
import { RootComponent } from './components/root-area/root/root.component';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule, TranslatePipe, TranslateService } from '@ngx-translate/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SelectWebsiteLanguageComponent } from './components/gadgets-area/select-website-language/select-website-language.component';
import { TransactionsListComponent } from './components/transactions-area/transactions-list/transactions-list.component';
import { TransactionsErrorsComponent } from './components/transactions-area/transactions-errors/transactions-errors.component';
import { RecurringOrdersComponent } from './components/recurring-orders-area/recurring-orders/recurring-orders.component';
import { ChangePasswordComponent } from './components/auth-area/change-password/change-password.component';
import { ProfileComponent } from './components/auth-area/user-profile/user-profile.component';
import { TransactionsDepositComponent } from './components/deposit-area/transactions-deposit/transactions-deposit.component';
import { DepositReportsComponent } from './components/deposit-area/deposit-reports/deposit-reports.component';
import { ToastrModule } from 'ngx-toastr';
import { PasswordExpiredDialogComponent } from './components/auth-area/password-expired-dialog/password-expired-dialog.component';
import { ForgotPasswordDialogComponent } from './components/auth-area/forgot-password-dialog/forgot-password-dialog.component';
import { PageTitleComponent } from './components/gadgets-area/page-title/page-title.component';
import { SpinnerOverlayComponent } from './components/layout-area/spinner-overlay/spinner-overlay.component';
import { SpinnerComponent } from './components/gadgets-area/spinner/spinner.component';
import { CurrencyPipe, DatePipe } from '@angular/common';

import { LoadingInterceptor } from './infrastructure/interceptors/loading.interceptor';
import { ErrorInterceptor } from './infrastructure/interceptors/error.interceptor';
import { ResetCookieExpirationTimeInterceptor } from './infrastructure/interceptors/reset-cookie-expiration-time.interceptor';
import { AddAuthorizationHeaderInterceptor } from './infrastructure/interceptors/add-authorization-header.interceptor';
import { AlertPreLeaveDialogComponent } from './components/auth-area/alert-pre-leave-dialog/alert-pre-leave-dialog.component';
import { TableComponent } from './components/gadgets-area/table/table.component';
import { CustomPaginator } from './components/gadgets-area/table/custom-paginator';

import { ResizableModule } from 'angular-resizable-element';
import { TransactionDetailsDialogComponent } from './components/transactions-area/transaction-details-dialog/transaction-details-dialog.component';
import { TransactionNotesDialogComponent } from './components/transactions-area/transaction-notes-dialog/transaction-notes-dialog.component';
import { TransactionSendVoucherDialogComponent } from './components/transactions-area/transaction-send-voucher-dialog/transaction-send-voucher-dialog.component';
import { TransactionHistoryFilterDialogComponent } from './components/transactions-area/transaction-history-filter-dialog/transaction-history-filter-dialog.component';
import { NewTransactionDialogComponent } from './components/transactions-area/new-transaction-dialog/new-transaction-dialog.component';
import { EqualValidator } from './infrastructure/validators/equal-validator.directive';
import { TransactionErrorHistoryFilterDialogComponent } from './components/transactions-area/transaction-error-history-filter-dialog/transaction-error-history-filter-dialog.component';
import { ReportViewerDialogComponent } from './components/deposit-area/report-viewer-dialog/report-viewer-dialog.component';

export function rootLoaderFactory(http: HttpClient) {
  console.log
  return new TranslateHttpLoader(http, './assets/i18n/', '.json')
}

@NgModule({
  declarations: [
    RootComponent,
    LayoutComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    MenuComponent,
    HomeComponent,
    SelectWebsiteLanguageComponent,
    TransactionsListComponent,
    TransactionsErrorsComponent,
    RecurringOrdersComponent,
    ChangePasswordComponent,
    ProfileComponent,
    TransactionsDepositComponent,
    DepositReportsComponent,
    PasswordExpiredDialogComponent,
    ForgotPasswordDialogComponent,
    PageTitleComponent,
    SpinnerOverlayComponent,
    SpinnerComponent,
    AlertPreLeaveDialogComponent,
    TableComponent,
    TransactionDetailsDialogComponent,
    TransactionNotesDialogComponent,
    TransactionSendVoucherDialogComponent,
    TransactionHistoryFilterDialogComponent,
    NewTransactionDialogComponent,
    EqualValidator,
    TransactionErrorHistoryFilterDialogComponent,
    ReportViewerDialogComponent,

  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: rootLoaderFactory,
        deps: [HttpClient]
      }
    })
    , BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ...matModules,
    ToastrModule.forRoot({
      timeOut: 4000,
      //positionClass: 'toast-top-full-width',
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      enableHtml: true,
      closeButton: false,
      autoDismiss: false,
      tapToDismiss: false,
      includeTitleDuplicates: true,
    }),
    ResizableModule,
    PortalModule
  ],
  providers: [
    TranslatePipe,
    DatePipe,
    CurrencyPipe,
    MatDatepickerModule,
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ResetCookieExpirationTimeInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AddAuthorizationHeaderInterceptor, multi: true },
    {
      provide: MatPaginatorIntl,
      useFactory: (translate: TranslateService) => {
        const service = new CustomPaginator();
        service.injectTranslateService(translate);
        return service;
      },
      deps: [TranslateService]
    },
  ],
  bootstrap: [RootComponent]
})
export class AppModule { }
