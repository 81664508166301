export class StringUtils {

    static leftPad(number: number, targetLength: number): string {
        var output = number + '';
        while (output.length < targetLength) {
            output = '0' + output;
        }
        return output;
    }

}
