import { DepositReportItem } from './../dtos/tgw/deposit-report-response';
import { ReportViewerDialogComponent } from './../components/deposit-area/report-viewer-dialog/report-viewer-dialog.component';
import { TransactionErrorHistoryFilterDialogComponent } from './../components/transactions-area/transaction-error-history-filter-dialog/transaction-error-history-filter-dialog.component';
import { TransactionSendVoucherDialogData } from './../dtos/dialogs/transaction-send-voucher-dialog-data';
import { TransactionSendVoucherDialogComponent } from './../components/transactions-area/transaction-send-voucher-dialog/transaction-send-voucher-dialog.component';
import { TransactionDetailsDialogComponent } from './../components/transactions-area/transaction-details-dialog/transaction-details-dialog.component';
import { TransactionNotesDialogData } from './../dtos/dialogs/transaction-notes-dialog-data';
import { AlertPreLeaveDialogComponent } from './../components/auth-area/alert-pre-leave-dialog/alert-pre-leave-dialog.component';
import { PasswordExpiredDialogData } from '../dtos/dialogs/password-expired-dialog-data';
import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PasswordExpiredDialogComponent } from '../components/auth-area/password-expired-dialog/password-expired-dialog.component';
import { ForgotPasswordDialogComponent } from '../components/auth-area/forgot-password-dialog/forgot-password-dialog.component';
import { TransactionNotesDialogComponent } from '../components/transactions-area/transaction-notes-dialog/transaction-notes-dialog.component';
import { TransactionHistoryObject } from '../dtos/tgw/transaction-history-response';
import { TransactionHistoryFilterDialogComponent } from '../components/transactions-area/transaction-history-filter-dialog/transaction-history-filter-dialog.component';
import { TransactionHistoryFilterDialogData } from '../dtos/dialogs/transaction-history-filter-dialog-data';
import { NewTransactionDialogComponent } from '../components/transactions-area/new-transaction-dialog/new-transaction-dialog.component';
import { NewTransactionDialogData } from '../dtos/dialogs/new-transaction-dialog-data';
import { TransactionErrorHistoryFilterDialogData } from '../dtos/dialogs/transaction-error-history-filter-dialog-data';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  private readonly DIALOG_MIN_WIDTH = "50vw";
  private readonly DIALOG_MAX_WIDTH = "80vw";
  private readonly DIALOG_MAX_HEIGHT = "80vh";

  constructor(
    private dialog: MatDialog
  ) { }

  openReportViewerDialog(data: DepositReportItem) {
    this.openDialog(ReportViewerDialogComponent, data, true);
  }

  openTransactionDetailsDialog(data: TransactionHistoryObject) {
    this.openDialog(TransactionDetailsDialogComponent, data, true);
  }

  openTransactionNotesDialog(data: TransactionNotesDialogData) {
    this.openDialog(TransactionNotesDialogComponent, data, true);
  }

  openTransactionSendVoucherDialog(data: TransactionSendVoucherDialogData) {
    this.openDialog(TransactionSendVoucherDialogComponent, data, true);
  }

  openAlertPreLeaveDialog() {
    this.openDialog(AlertPreLeaveDialogComponent, null, true);
  }

  openNewTransactionDialog(data: NewTransactionDialogData) {
    this.openDialog(NewTransactionDialogComponent, data, true);
  }

  openTransactionHistoryFilterDialog(data: TransactionHistoryFilterDialogData) {
    this.openDialog(TransactionHistoryFilterDialogComponent, data, true);
  }

  openTransactionErrorHistoryFilterDialog(data: TransactionErrorHistoryFilterDialogData) {
    this.openDialog(TransactionErrorHistoryFilterDialogComponent, data, true);
  }

  openForgotPasswordDialog() {
    this.openDialog(ForgotPasswordDialogComponent, null, true);
  }

  openPasswordExpiredDialog(data: PasswordExpiredDialogData) {
    this.openDialog(PasswordExpiredDialogComponent, data, true);
  }

  private openDialog(component: ComponentType<any>, data: any, disableClose: boolean = true) {
    return new Promise((resolve, reject) => {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = disableClose;
      dialogConfig.autoFocus = true;
      dialogConfig.minWidth = this.DIALOG_MIN_WIDTH;
      dialogConfig.maxWidth = this.DIALOG_MAX_WIDTH;
      dialogConfig.maxHeight = this.DIALOG_MAX_HEIGHT;
      dialogConfig.data = data;
      this.dialog
        .open(component, dialogConfig)
        .afterClosed()
        .subscribe(result => {
          resolve(result);
        });
    });
  }
}
