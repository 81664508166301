<div class="header-container">
    <div class="first-container">
        <button mat-icon-button class="example-icon" (click)="menuClick()">
            <mat-icon>menu</mat-icon>
        </button>
        <label translate>system_name</label>
    </div>
    <div class="second-container">

        <button mat-button [matMenuTriggerFor]="menu">
            <label>{{userName}}</label>&nbsp;
            <mat-icon>arrow_drop_down</mat-icon>
        </button>
        <mat-menu #menu="matMenu" xPosition="before">
            <button mat-menu-item (click)="navigate('/profile')">
                <mat-icon>person</mat-icon>&nbsp;
                <span translate>header.user_menu.profile_info</span>
            </button>
            <button mat-menu-item (click)="navigate('/changePassword')">
                <mat-icon>vpn_key</mat-icon>&nbsp;
                <span translate>header.user_menu.change_password</span>
            </button>
            <button mat-menu-item (click)="clearCache()">
                <mat-icon>clear</mat-icon>&nbsp;
                <span translate>header.user_menu.clear_cache</span>
            </button>
            <button mat-menu-item (click)="logout()">
                <mat-icon>logout</mat-icon>&nbsp;
                <span translate>header.user_menu.logout</span>
            </button>
        </mat-menu>
        <app-select-website-language></app-select-website-language>
    </div>
</div>