import { Constants } from '../../../infrastructure/constants';
import { UserDetailsResponse } from '../../../dtos/tgw/user-details-response';
import { TgwUserService } from '../../../services/tgw/tgw-user.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';

@Component({
  selector: 'app-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class ProfileComponent implements OnInit {
  languages = Constants.languages;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  data: UserDetailsResponse = {
    company: {},
    sender: {}
  };

  constructor(
    private tgwUserService: TgwUserService
  ) { }

  getDateTime(): Date {
    if (this.data?.created)
      return new Date(this.data.created * 1000);
    return null;
  }

  ngOnInit(): void {
    this.tgwUserService.getUserDetails().subscribe(response => {
      this.data = response?.data;
      this.accordion.openAll();
    });
  }
}
