import { TranslateConfigService } from './../../services/translate-config.service';
import { AuthService } from '../../services/auth.service';
import { NotifyService } from '../../services/notify.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private notifyService: NotifyService,
    private authService: AuthService,
    private translateConfigService: TranslateConfigService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const currentUserJson = this.authService.getCurrentUser();
    if (!currentUserJson) {
      setTimeout(async () => {
        const msg = this.translateConfigService.getValue("auth.messages.please_login_first");
        this.notifyService.error(msg);
      }, 10);
      this.authService.logout(state.url);
      return false
    }
    return true;
  }
}
