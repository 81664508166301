import { environment } from './../../../environments/environment';
import { DepositResponse } from '../../dtos/tgw/deposit-response';
import { DepositReportResponse } from '../../dtos/tgw/deposit-report-response';
import { BaseResponse } from '../../dtos/tgw/base-response';
import { AshFullResponse } from '../../dtos/tgw/ash-full-response';
import { AshFullRequest } from '../../dtos/tgw/ash-full-request';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TgwEmvService {
  baseApiUrl = () => environment.tgwBaseApiUrl + "/emv";

  constructor(
    private http: HttpClient,
  ) { }

  creditAshFullTransaction(request: AshFullRequest): Observable<BaseResponse<AshFullResponse>> {
    return this.http.post<BaseResponse<AshFullResponse>>(this.baseApiUrl() + "/full", request);
  }

  getDepositReport(): Observable<BaseResponse<DepositReportResponse>> {
    return this.http.get<BaseResponse<DepositReportResponse>>(this.baseApiUrl() + "/depositReport");
  }

  deposit(): Observable<BaseResponse<DepositResponse>> {
    return this.http.patch<BaseResponse<DepositResponse>>(this.baseApiUrl() + "/deposit", null);
  }
}


