import { SpinnerOverlayService } from './../../services/spinner-overlay.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { catchError, map, Observable, throwError } from 'rxjs';
import { Constants } from '../constants';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {

  constructor(
    private spinnerOverlayService: SpinnerOverlayService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.endsWith(".json")) // action no needed on load translate items
      return next.handle(request);

    const loadingOff = request.headers.has(Constants.loadingOffKey);
    if (!loadingOff)
      this.spinnerOverlayService.show();
    return next.handle(request)
      .pipe(catchError((err: any) => {
        if (!loadingOff)
          this.spinnerOverlayService.hide();
        return throwError(err);
      }))
      .pipe(map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
        if (evt instanceof HttpResponse && !loadingOff) {
          this.spinnerOverlayService.hide();
        }
        return evt;
      }));
  }
}
