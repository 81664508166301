import { Constants } from './../../../infrastructure/constants';
import { TgwApiUtils } from './../../../utils/tgw-api-utils';
import { TranslateConfigService } from '../../../services/translate-config.service';
import { PasswordExpiredDialogData } from '../../../dtos/dialogs/password-expired-dialog-data';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TgwSecurityService } from '../../../services/tgw/tgw-security.service';
import { NotifyService } from '../../../services/notify.service';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'app-password-expired',
  templateUrl: './password-expired-dialog.component.html',
  styleUrls: ['./password-expired-dialog.component.scss']
})
export class PasswordExpiredDialogComponent {
  readonly merchantPasswordRule = Constants.merchantPasswordRule;
  @ViewChild('username') username: NgModel;
  @ViewChild('newPassword') newPassword: NgModel;
  @ViewChild('confirmNewPassword') confirmNewPassword: NgModel;
  loading: boolean = false;
  errorMsg: string = '';
  confirmPasswordHide = true;
  passwordHide = true;

  data = {
    username: this.requestData.username,
    password: this.requestData.currentPassword,
    newPassword: '',
    confirmNewPassword: ''
  };

  constructor(
    private dialogRef: MatDialogRef<PasswordExpiredDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public requestData: PasswordExpiredDialogData,
    private tgwSecurityService: TgwSecurityService,
    private notify: NotifyService,
    private translateConfigService: TranslateConfigService
  ) { }

  onCancel() {
    if (this.requestData?.callback)
      this.requestData.callback(null);
    this.dialogRef.close();
  }

  async onSubmit() {
    if (this.username.invalid || this.newPassword.invalid || this.confirmNewPassword.invalid || this.data.newPassword !== this.data.confirmNewPassword) {
      return;
    }
    this.loading = true;
    try {
      const response = await this.tgwSecurityService.changePassword(this.data.username, this.data.password, this.data.newPassword).toPromise();
      if (TgwApiUtils.TgwResponseIsSuccess(response) && response?.data?.result) {
        this.notify.success(await this.translateConfigService.getValueAsync('auth.messages.password_changed_successfully'));
        if (this.requestData?.callback)
          this.requestData.callback(this.data.newPassword);
        this.dialogRef.close();
      }
    } catch (err: any) {
      this.notify.exception(err);
    } finally {
      this.loading = false;
    }
  }

}
