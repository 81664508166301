<div>
    <p [innerHtml]="'transactions_deposit.reload_abs_parameters_description' | translate"></p>
    <button mat-stroked-button color="primary" (click)="onReloadAbsParameters()">
        <mat-icon>file_download</mat-icon>
        <label [innerHtml]="'transactions_deposit.reload_abs_parameters' | translate"></label>
    </button>
</div>
<div>
    <p [innerHtml]="'transactions_deposit.transactions_deposit_description' | translate"></p>
    <button mat-stroked-button color="primary" (click)="onTransactionsDeposit()">
        <mat-icon>publish</mat-icon>
        <label [innerHtml]="'transactions_deposit.transactions_deposit' | translate"></label>
    </button>
</div>