import { environment } from './../../../../environments/environment';
import { CompanyType } from './../../../infrastructure/types/company.type';
import { TgwUserService } from './../../../services/tgw/tgw-user.service';
import { CurrentUserDTO } from './../../../dtos/current-user.dto';
import { Constants } from './../../../infrastructure/constants';
import { DialogService } from './../../../services/dialog.service';
import { StringUtils } from './../../../utils/string-utils';
import { TranslateConfigService } from './../../../services/translate-config.service';
import { TgwApiUtils } from './../../../utils/tgw-api-utils';
import { AuthService } from './../../../services/auth.service';
import { Component, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { NotifyService } from '../../../services/notify.service';
import { TgwSecurityService } from '../../../services/tgw/tgw-security.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements AfterViewInit {
  readonly websiteVersion: string = environment.appVersion;
  companyTypeValues = CompanyType;
  verifoneLogoUrl = Constants.VerifoneLogoUrlBlack;
  isracardLogoUrl = Constants.IsracardLogoUrl;
  company: CompanyType = null;
  @ViewChild('buttonState') buttonState: ElementRef<HTMLSpanElement>;
  @ViewChild('loginDiv') loginDiv: ElementRef<HTMLDivElement>;
  passwordHide = true;
  loading = false;
  authError = '';
  returnUrl = '';
  directLoginBase64: string;

  loginFormGroup: FormGroup = this.formBuilder.group({
    'username': new FormControl('', [Validators.required]),
    'password': new FormControl('', [Validators.required])
  });

  constructor(
    private dialogService: DialogService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private tgwSecurityService: TgwSecurityService,
    private translateConfigService: TranslateConfigService,
    private router: Router,
    private route: ActivatedRoute,
    private notify: NotifyService,
    private tgwUserService: TgwUserService,
  ) {
    this.returnUrl = route.snapshot.queryParamMap.get('returnUrl') || Constants.defaultPage;
    this.setCompany(route.snapshot.queryParamMap.get('company'));
  }

  setCompany(value: string) {
    value = value ?? "";
    switch (value.toLowerCase()) {
      default:
      case "verifone":
        this.company = CompanyType.VERIFONE;
        break;
      case "isracard":
        this.company = CompanyType.ISRACARD;
        break;
    }
  }

  ngAfterViewInit(): void {
    this.checkDirectLogin();
  }

  async checkDirectLogin() {
    const directLoginBase64 = this.route.snapshot.queryParamMap.get('directLogin');
    if (!directLoginBase64) {
      return;
    }

    try {
      const json = atob(directLoginBase64);
      const user: CurrentUserDTO = JSON.parse(json);
      if (user) {
        this.authService.setCurrentUser(user);
        const userDetails = await this.tgwUserService.getUserDetails().toPromise();
        if (TgwApiUtils.TgwResponseIsSuccess(userDetails)) {
          setTimeout(async () => {
            this.router.navigateByUrl(this.returnUrl);
          }, 50);
        }
      }
    } catch (err: any) { }
  }


  forgotPassword() {
    this.dialogService.openForgotPasswordDialog();
  }

  async resetLoginButton() {
    this.buttonState.nativeElement.innerHTML = await this.translateConfigService.getValueAsync('login.submit_text');
    this.loginDiv.nativeElement.classList.remove('ok');
    this.loginDiv.nativeElement.classList.remove('loading');
  }

  async onSubmit(event: SubmitEvent) {
    event.preventDefault();
    if (this.loading || this.loginFormGroup.invalid)
      return;

    this.loading = true;
    this.loginDiv.nativeElement.classList.add('loading');
    this.buttonState.nativeElement.innerHTML = 'Authenticating';
    const userName = this.loginFormGroup.controls["username"].value;
    const password = this.loginFormGroup.controls["password"].value;
    try {
      this.authError = '';
      const response = await this.tgwSecurityService.createAccessToken(userName, password).toPromise();
      if (TgwApiUtils.TgwResponseIsSuccess(response)) {
        this.loginDiv.nativeElement.classList.add('ok');
        this.buttonState.nativeElement.innerHTML = await this.translateConfigService.getValueAsync('login.welcome_back');
        setTimeout(async () => {
          await this.resetLoginButton();
          this.authService.setCurrentUser({
            username: userName,
            accessToken: response.data.accessToken,
            ttl: response.data.ttl,
            merchantType: response.data.merchantType
          });
          this.router.navigateByUrl(this.returnUrl);
          this.loading = false;
        }, 3000);
      }
    } catch (err: any) {
      await this.resetLoginButton();
      this.loading = false;
      if (err?.error?.data) {
        this.authError = err?.error?.data?.errorCode ?
          await this.translateConfigService.getValueAsync(`login.errors.login_error_code_${StringUtils.leftPad(err?.error.data.errorCode, 2)}`) :
          `(${err?.error?.statusCode}) ${err?.error?.errorMessage ?? "Login error"}`;

        if (err.error.data.errorCode === 12) {
          this.dialogService.openPasswordExpiredDialog({
            username: userName,
            currentPassword: password,
            callback: (newPass: string) => {
              if (newPass) {
                this.loginFormGroup.get("password").setValue(newPass);
                this.onSubmit(event);
              }
            }
          });
        }
      } else {
        this.notify.exception(err);
      }
    }
  }
}
