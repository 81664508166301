import { Constants } from './../../../infrastructure/constants';
import { TransactionHistoryFilterDialogData } from '../../../dtos/dialogs/transaction-history-filter-dialog-data';
import { TransactionHistoryRequest } from './../../../dtos/tgw/transaction-history-request';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-transaction-history-filter-dialog',
  templateUrl: './transaction-history-filter-dialog.component.html',
  styleUrls: ['./transaction-history-filter-dialog.component.scss']
})
export class TransactionHistoryFilterDialogComponent {
  transactionTypeValues = Constants.TransactionTypeValues;
  data: TransactionHistoryRequest;

  constructor(
    private dialogRef: MatDialogRef<TransactionHistoryFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public requestData: TransactionHistoryFilterDialogData
  ) {
    this.data = requestData.request;
  }

  async onFilter() {
    if (this.data?.dateStart)
      this.data.dateStart += " 00:00:00";

    if (this.data?.dateEnd)
      this.data.dateEnd += " 23:59:59";

    if (this.requestData.callBack)
      this.requestData.callBack(this.data)
    this.dialogRef.close();
  }

  onReset() {
    this.data = {};
  }

  onCancel() {
    this.dialogRef.close();
  }
}
