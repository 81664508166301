import { HttpStatusCode } from "@angular/common/http";
import { BaseResponse } from "../dtos/tgw/base-response";

export class TgwApiUtils {

    static TgwResponseIsSuccess(response: BaseResponse<any>): boolean {
        return response?.statusCode < HttpStatusCode.BadRequest;
    }

}
