import { TranslateConfigService } from './translate-config.service';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class PageTitleService {
  private static pageTitleKey: string = '';
  private static pageTitle: string = '';

  constructor(
    private translateConfigService: TranslateConfigService,
    private titleService: Title
  ) {
    translateConfigService.onLangChange.subscribe(() => {
      this.setPageTitleKey(PageTitleService.pageTitleKey);
    });
  }

  setPageTitleKey(key: string) {
    PageTitleService.pageTitleKey = key;
    PageTitleService.pageTitle = this.translateConfigService.getValue(key);
    this.titleService.setTitle(PageTitleService.pageTitle);
  }

  getPageTitle(): string {
    return PageTitleService.pageTitle;
  }
}
