import { DialogService } from './../../../services/dialog.service';
import { TgwEmvService } from '../../../services/tgw/tgw-emv.service';
import { TranslateConfigService } from './../../../services/translate-config.service';
import { NotifyService } from './../../../services/notify.service';
import { TgwApiUtils } from './../../../utils/tgw-api-utils';
import { TgwInitService } from '../../../services/tgw/tgw-init.service';
import { Component } from '@angular/core';

@Component({
  selector: 'app-transactions-deposit',
  templateUrl: './transactions-deposit.component.html',
  styleUrls: ['./transactions-deposit.component.scss']
})
export class TransactionsDepositComponent {

  constructor(
    private tgwInitService: TgwInitService,
    private tgwEmvService: TgwEmvService,
    private notify: NotifyService,
    private dialogService: DialogService,
    private translateConfigService: TranslateConfigService
  ) { }

  async onReloadAbsParameters() {
    try {
      const response = await this.tgwInitService.getInitXml().toPromise();
      if (TgwApiUtils.TgwResponseIsSuccess(response)) {
        this.notify.success(this.translateConfigService.getValue("transactions_deposit.abs_parameters_loaded_successfully"))
      } else {
        this.notify.error(this.translateConfigService.getValue("error"))
      }
    } catch (err: any) { }
  }

  async onTransactionsDeposit() {
    try {
      const response = await this.tgwEmvService.deposit().toPromise();
      if (TgwApiUtils.TgwResponseIsSuccess(response)) {
        if (response?.data?.report) {
          this.notify.success(this.translateConfigService.getValue("transactions_deposit.transactions_successfully_deposited"))
          this.dialogService.openReportViewerDialog({
            created: (new Date()).toISOString(),
            error: 0,
            report: response?.data?.report,
            success: true
          });
        } else {
          this.notify.success(this.translateConfigService.getValue("transactions_deposit.no_transactions_for_deposit"))
        }
      } else {
        this.notify.error(this.translateConfigService.getValue("error"))
      }
    } catch (err: any) { }
  }

}
