import { TranslateConfigService } from './../../../services/translate-config.service';
import { Constants } from './../../../infrastructure/constants';
import { ITableData } from '../../../infrastructure/interfaces/table/table-data.interface';
import { AfterViewInit, Component, DoCheck, Inject, Input, ViewChild, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { sha1 } from './../../../infrastructure/helpers/sha1.function';
import { TableUtil } from "./tableUtil";

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements AfterViewInit, DoCheck {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  paginatorOptions = {
    pageSizeOptions: [5, 10, 15, 25, 50, 100],
    pageSize: 5,
  }
  @Input() data: ITableData<any>;
  dataHash: string = null;
  dataSource: MatTableDataSource<any>;

  constructor(
    private translateConfigService: TranslateConfigService
  ) {
    const json = localStorage.getItem(Constants.tablePageSizeKey);
    if (json) {
      this.paginatorOptions.pageSize = JSON.parse(json);
    }
  }

  exportTable() {
    TableUtil.exportTableToExcel("ExampleMaterialTable");
  }

  setDataSource() {
    const hash = sha1(JSON.stringify(this.data));
    if (this.data && this.dataHash !== hash) {
      //set defaults
      this.data.filter = this.data.filter || true;
      this.data.headerActions = this.data.headerActions || [];
      this.data.rowActions = this.data.rowActions || [];

      if (!this.data.getRowColor)
        this.data.getRowColor = () => "black";

      if (this.data?.headerActions) {
        this.data.headerActions.forEach(item => {
          if (item.translateToolTip === undefined || item.translateToolTip === null)
            item.translateToolTip = true;
        });
      }

      if (this.data?.rowActions) {
        this.data.rowActions.forEach(item => {
          if (item.translateToolTip === undefined || item.translateToolTip === null)
            item.translateToolTip = true;
        });
      }

      this.dataSource = new MatTableDataSource(this.data?.items);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataHash = hash;
    }
  }

  async ngAfterViewInit() {
    this.setDataSource();
  }

  ngDoCheck(): void {
    this.setDataSource();
  }

  displayedColumns() {
    let res: string[] = [];
    if (this.data.getRowIcons) {
      res.push('__row_Icons__');
    }

    if (this.data?.columns)
      this.data.columns.forEach(x => res.push(x.name));

    if (this.data.rowActions?.length > 0 && res.findIndex(x => x === 'actions') === -1) {
      res.push('__actions__');
    }
    return res;
  };

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  getToolTip(translateToolTip: boolean, text: string) {
    if (translateToolTip)
      return this.translateConfigService.getValue(text);
    return text;
  }
}