import * as XLSX from "xlsx";

const getFileName = (name: string) => {
  let timeSpan = new Date().toISOString();
  let sheetName = name || "ExportResult";
  let fileName = `${sheetName}-${timeSpan}`;
  return {
    sheetName,
    fileName
  };
};
export class TableUtil {
  static exportTableToExcel(tableId: string, name?: string) {
    let { sheetName, fileName } = getFileName(name);
    let ws: 
 XLSX.WorkSheet=XLSX.utils.table_to_sheet(document.getElementById(tableId));

 ws['!cols'] = [];
ws['!cols'][0] = { hidden: true };
ws['!cols'][8] = { hidden: true };

/* here 12 is your column number (n-1) */
const wb: XLSX.WorkBook = XLSX.utils.book_new();

XLSX.utils.book_append_sheet(wb, ws, 'Servers');
/* save to file */
XLSX.writeFile(wb, `${fileName}.xlsx`);

  }
}
