<div [dir]="'dir' | translate">
    <mat-dialog-content #dialogContent>
        <h1 class="title" mat-dialog-title translate>
            <mat-icon>filter_alt</mat-icon>&nbsp;
            <label translate>transaction_error_history_filter_dialog.title</label>
        </h1>
        <ng-container>

            <mat-form-field appearance="fill">
                <mat-label translate>transaction_history_filter_dialog.from_date</mat-label>
                <input matInput type="date" #dateStart="ngModel" [(ngModel)]="data.dateStart">
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label translate>transaction_history_filter_dialog.to_date</mat-label>
                <input matInput type="date" #dateEnd="ngModel" [(ngModel)]="data.dateEnd">
            </mat-form-field>

            <div>
                <mat-form-field appearance="fill">
                    <mat-label translate>payment.amount</mat-label>
                    <input matInput type="number" #amount="ngModel" [(ngModel)]="data.amount" step="0.01">
                </mat-form-field>

                <mat-form-field appearance="fill" color="primary">
                    <mat-label translate>payment.currency</mat-label>
                    <mat-select #currency="ngModel" [(ngModel)]="data.currencyCode">
                        <mat-option [value]=null>
                            <label translate>all</label>
                        </mat-option>
                        <mat-option [value]=376>₪</mat-option>
                        <mat-option [value]=840>$</mat-option>
                        <mat-option [value]=978>€</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field appearance="fill">
                    <mat-label translate>payment.error_code</mat-label>
                    <input matInput type="number" #transId="ngModel" [(ngModel)]="data.errorCode" min="1">
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label translate>payment.last_4</mat-label>
                    <input matInput type="text" #last4="ngModel" [(ngModel)]="data.last4" maxlength="4" name="mp_last4"
                        pattern="^[0-9]{4}$|^$">
                    <mat-error *ngIf="last4.hasError('pattern')" translate>
                        transaction_filter_dialog.errors.last_4_policy_error
                    </mat-error>
                </mat-form-field>
            </div>
        </ng-container>
    </mat-dialog-content>
    <mat-dialog-actions align="end">

        <button mat-stroked-button color="primary" type="button" (click)="onReset()">
            <mat-icon>restart_alt</mat-icon>&nbsp;
            <label translate>dialog.reset</label>
        </button>

        <button mat-stroked-button color="primary" type="button" (click)="onCancel()">
            <label translate>dialog.cancel</label>
        </button>

        <button mat-flat-button color="primary" (click)="onFilter()" cdkFocusInitial>
            <mat-icon>filter_alt</mat-icon>&nbsp;
            <label translate>filter</label>
        </button>

    </mat-dialog-actions>
</div>