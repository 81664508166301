import { TranslateService } from '@ngx-translate/core';
import { MatPaginatorIntl } from "@angular/material/paginator";
import { Constants } from './../../../infrastructure/constants';

export class CustomPaginator extends MatPaginatorIntl {
    translateConfigService: TranslateService;

    constructor() {
        super();
    }

    injectTranslateService(translateConfigService: TranslateService) {
        this.translateConfigService = translateConfigService;
        this.translateConfigService.onLangChange.subscribe(() => {
            this.translateLabels();
        });
        this.translateLabels();
    }

    translateLabels() {
        super.itemsPerPageLabel = this.translateConfigService.instant('paginator.items_per_page');
        super.firstPageLabel = this.translateConfigService.instant('paginator.first_page');
        super.lastPageLabel = this.translateConfigService.instant('paginator.last_page');
        super.nextPageLabel = this.translateConfigService.instant('paginator.next_page');
        super.previousPageLabel = this.translateConfigService.instant('paginator.previous_page');
        super.getRangeLabel = (page: number, pageSize: number, length: number) => {
            localStorage.setItem(Constants.tablePageSizeKey, JSON.stringify(pageSize));
            const from = page * pageSize + 1;
            let to = from + pageSize - 1;
            if (to > length)
                to = length;
            const of = this.translateConfigService.instant('paginator.of');
            return `${from} - ${to} ${of} ${length}`;
        };
        this.changes.next();
    }
}