import { TransactionSendVoucherDialogData } from './../../../dtos/dialogs/transaction-send-voucher-dialog-data';
import { TranslateConfigService } from './../../../services/translate-config.service';
import { TgwTransactionService } from '../../../services/tgw/tgw-transaction.service';
import { NotifyService } from './../../../services/notify.service';
import { Component, Inject, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TgwApiUtils } from './../../../utils/tgw-api-utils';

@Component({
  selector: 'app-transaction-send-voucher-dialog',
  templateUrl: './transaction-send-voucher-dialog.component.html',
  styleUrls: ['./transaction-send-voucher-dialog.component.scss']
})
export class TransactionSendVoucherDialogComponent {
  @ViewChild('transId') transId: NgModel;
  @ViewChild('email') email: NgModel;
  data = {
    transId: this.requestData?.transId ?? 0,
    email: ''
  };

  constructor(
    private dialogRef: MatDialogRef<TransactionSendVoucherDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public requestData: TransactionSendVoucherDialogData,
    private translateConfigService: TranslateConfigService,
    private notify: NotifyService,
    private tgwTransactionService: TgwTransactionService
  ) { }

  async onSubmit() {
    if (this.transId.invalid || this.email.invalid) {
      return;
    }

    try {
      const response = await this.tgwTransactionService.sendTransactionVoucher(this.data.transId, this.data.email).toPromise();
      if (TgwApiUtils.TgwResponseIsSuccess(response) && response?.data?.result) {
        this.dialogRef.close();
        this.notify.success(await this.translateConfigService.getValueAsync('transaction_send_voucher_dialog.the_email_was_sent_successfully'));
      }
    } catch { }
  }
  onCancel() {
    this.dialogRef.close();
  }
}
