import { TgwApiUtils } from './../../../utils/tgw-api-utils';
import { TranslateConfigService } from '../../../services/translate-config.service';
import { Component, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TgwSecurityService } from '../../../services/tgw/tgw-security.service';
import { NotifyService } from '../../../services/notify.service';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'app-forgot-password-dialog',
  templateUrl: './forgot-password-dialog.component.html',
  styleUrls: ['./forgot-password-dialog.component.scss']
})
export class ForgotPasswordDialogComponent {
  @ViewChild('username') username: NgModel;
  @ViewChild('companyId') companyId: NgModel;
  readonly companyIdRule = new RegExp(/^\d+$/);
  errorMsg: string = '';
  data = {
    username: '',
    companyId: ''
  };

  constructor(
    private dialogRef: MatDialogRef<ForgotPasswordDialogComponent>,
    private tgwSecurityService: TgwSecurityService,
    private notify: NotifyService,
    private translateConfigService: TranslateConfigService
  ) { }

  onCancel() {
    this.dialogRef.close();
  }

  async onSubmit() {
    if (this.username.invalid || this.companyId.invalid) {
      return;
    }

    try {
      const response = await this.tgwSecurityService.forgotPassword(this.data.username, this.data.companyId).toPromise();
      if (TgwApiUtils.TgwResponseIsSuccess(response)) {
        this.notify.success(await this.translateConfigService.getValueAsync('forgot_password.message_of_success'));
        this.dialogRef.close();
      }
    } catch (err: any) {
      this.notify.exception(err);
    }
  }
}
