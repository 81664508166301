import { Component } from '@angular/core';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent {
  menuVisible: boolean = true;
  menuStyles = {};
  headerMainFooterStyles = {};

  resetStyles() {
    this.menuStyles = {
      display: this.menuVisible ? '' : 'none'
    };
    this.headerMainFooterStyles = {
      "grid-column": `span ${this.menuVisible ? '26' : '30'}`
    };
  }

  constructor() {
    this.resetStyles();
  }

  menuClick() {
    this.menuVisible = !this.menuVisible;
    this.resetStyles();
  }
}
