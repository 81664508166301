import { TranslateConfigService } from './../../services/translate-config.service';
import { AuthService } from '../../services/auth.service';
import { NotifyService } from '../../services/notify.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router, RoutesRecognized } from '@angular/router';
import { Location } from '@angular/common';
import { filter, pairwise } from 'rxjs';
import { Constants } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class AnonymousGuard implements CanActivate {
  constructor(
    private notifyService: NotifyService,
    private authService: AuthService,
    private translateConfigService: TranslateConfigService,
    private router: Router
  ) { }

  canActivate(): boolean {
    const currentUserJson = this.authService.getCurrentUser();
    if (currentUserJson) {
      setTimeout(async () => {
        const msg = this.translateConfigService.getValue("auth.messages.please_logout_first");
        this.notifyService.error(msg);
      }, 10);
      this.router.navigateByUrl(Constants.defaultPage);
      return false
    }
    return true;
  }
}
