<div class="action-buttons">
  <button mat-stroked-button color="primary" (click)="accordion.openAll()"><mat-icon>expand_less</mat-icon>&nbsp;{{'expand_all'|translate}}</button>
  <button mat-stroked-button color="primary" (click)="accordion.closeAll()"><mat-icon>expand_more</mat-icon>&nbsp;{{'collapse_all'|translate}}</button>
</div>
<mat-accordion class="headers-align" multi>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title translate>user_profile.general</mat-panel-title>
      <mat-panel-description>
        <span></span>
        <mat-icon>account_circle</mat-icon>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <div class="panel-body">
      <mat-form-field appearance="fill">
        <mat-label translate>auth.username</mat-label>
        <input matInput class="left-text" type="text" [(ngModel)]="data.username" readonly="true">
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label translate>user_profile.terminal_number</mat-label>
        <input matInput class="left-text" type="text" [(ngModel)]="data.terminalNumber" readonly="true">
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label translate>created_time</mat-label>
        <input matInput class="left-text" type="datetime-local" [ngModel]="getDateTime() | date:'yyyy-MM-ddTHH:mm'"
          readonly="true">
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label translate>user_profile.device_type</mat-label>
        <input matInput class="left-text" type="text" [(ngModel)]="data.deviceType" readonly="true">
      </mat-form-field>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title translate>user_profile.business_details</mat-panel-title>
      <mat-panel-description>
        <span></span>
        <mat-icon>business</mat-icon>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <div class="panel-body">
      <mat-form-field appearance="fill">
        <mat-label translate>auth.company_id</mat-label>
        <input matInput class="left-text" type="text" [(ngModel)]="data.company.number" readonly="true">
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label translate>name</mat-label>
        <input matInput type="text" [(ngModel)]="data.company.name" readonly="true">
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label translate>email</mat-label>
        <input matInput class="left-text" type="email" [(ngModel)]="data.company.email" readonly="true">
      </mat-form-field>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title translate>user_profile.invoice_details</mat-panel-title>
      <mat-panel-description>
        <span></span>
        <mat-icon>receipt_long</mat-icon>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <div class="panel-body">
      <mat-form-field appearance="fill">
        <mat-label translate>name</mat-label>
        <input matInput type="text" [(ngModel)]="data.sender.name" readonly="true">
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label translate>email</mat-label>
        <input matInput class="left-text" type="email" [(ngModel)]="data.sender.email" readonly="true">
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label translate>language</mat-label>
        <mat-select [(ngModel)]="data.voucherLanguage" disabled>
          <mat-option *ngFor="let lang of languages" [value]="lang.value">
            {{lang.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

    </div>
  </mat-expansion-panel>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title translate>user_profile.permissions</mat-panel-title>
      <mat-panel-description>
        <span></span>
        <mat-icon>rule</mat-icon>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <div class="panel-body">
      <mat-slide-toggle color="primary" [(ngModel)]="data.invoiceSupport" disabled>
        {{'user_profile.invoice_support'|translate}}</mat-slide-toggle>
      <mat-slide-toggle color="primary" [(ngModel)]="data.allowDuplicatedTransactions" disabled>
        {{'user_profile.allow_duplicated_transactions'|translate}}</mat-slide-toggle>
      <mat-slide-toggle color="primary" [(ngModel)]="data.supportRecurringOrders" disabled>
        {{'user_profile.support_recurring_orders'|translate}}</mat-slide-toggle>
      <mat-slide-toggle color="primary" [(ngModel)]="data.supportRefundTrans" disabled>
        {{'user_profile.support_refund_transactions'|translate}}</mat-slide-toggle>
      <mat-slide-toggle color="primary" [(ngModel)]="data.supportCancelTrans" disabled>
        {{'user_profile.support_cancel_transactions'|translate}}</mat-slide-toggle>
      <mat-slide-toggle color="primary" [(ngModel)]="data.supportNewTrans" disabled>
        {{'user_profile.support_new_transactions'|translate}}</mat-slide-toggle>
      <mat-slide-toggle color="primary" [(ngModel)]="data.enableOnDemandTransmit" disabled>
        {{'user_profile.enable_on_demand_transmit'|translate}}</mat-slide-toggle>
    </div>
  </mat-expansion-panel>
</mat-accordion>