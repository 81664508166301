import { AuthService } from './../../../services/auth.service';
import { Constants } from './../../../infrastructure/constants';
import { TgwUserService } from './../../../services/tgw/tgw-user.service';
import { Router } from '@angular/router';
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-alert-pre-leave-dialog',
  templateUrl: './alert-pre-leave-dialog.component.html',
  styleUrls: ['./alert-pre-leave-dialog.component.scss']
})
export class AlertPreLeaveDialogComponent implements OnInit, OnDestroy {
  loading: boolean = false;
  seconds: number = Constants.secondsBeforeLeaveTheSystem;
  interval: any = null;
  
  constructor(
    private dialogRef: MatDialogRef<AlertPreLeaveDialogComponent>,
    private authService: AuthService,
    private tgwUserService: TgwUserService,
    private router: Router
  ) { }

  ngOnDestroy(): void {
    this.stopTimer();
  }

  ngOnInit(): void {
    this.interval = setInterval(() => {
      this.seconds--;
      if (this.seconds === 0) {
        this.stopTimer();
        this.onTimeOut();
      }
    }, 1000)
  }

  stopTimer() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  onCancel() {
    this.stopTimer();
    this.dialogRef.close();
    this.authService.logout(null);
  }

  onTimeOut() {
    this.stopTimer();
    this.dialogRef.close();
    this.authService.logout(this.router.url);
  }

  async onRenewActivity() {
    try {
      this.stopTimer();
      this.loading = true;
      await this.tgwUserService.getUserDetails().toPromise();
    }
    catch (err: any) {
      this.authService.logout(this.router.url);
    }
    finally {
      this.dialogRef.close();
    }
  }
}
